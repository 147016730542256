<template>
  <div v-show="isShowLogin">
    <div class="visibulity"></div>
    <div class="login-main">
      <div class="w3Title">
        <span>{{$t('w3AccountLogin')}}</span>
      </div>
      <div class="w3Container">
        <aui-form
          ref="form"
          :model="formData"
          :rules="rules"
          label-width="0"
          validate-type="text"
        >
          <aui-form-item
            label=""
            prop="users"
          >
            <aui-input
              v-model="formData.userId"
              :placeholder="$t('w3Account')"
            ></aui-input>
          </aui-form-item>
          <aui-form-item
            label=""
            prop="password"
          >
            <ssoPwd
              v-model="formData.password"
              :placeholder="$t('password')"
            ></ssoPwd>
            <div class="web_show">
              <sso-error
                v-if="isUserId ||failLogonCount > 0||lockedTime > 0"
                :message="webError"
              ></sso-error>
            </div>
          </aui-form-item>
          <aui-form-item
            prop="imageCode"
            v-if="imgCodeFlag"
          >
            <ssoImageCode
              v-model="formData.imageCode"
              :src="src"
              @recode="recode"
              :placeholder="$t('codeImage')"
            ></ssoImageCode>
          </aui-form-item>
          <aui-form-item>
            <aui-button
              type="primary"
              class="aui-btn-full"
              @click="submit"
              :disabled="userShow || pwdShow"
            >{{$t('login')}}</aui-button>
          </aui-form-item>
        </aui-form>
        <div class="modify_url">
          <div
            class="forgetPwd"
            @click="forgetPassword"
          >
            {{$t('forgetPwd')}}
          </div>
          <div
            class="updatePwd"
            @click="updatePassword"
          >
            {{$t('modifyPwd')}}
          </div>
        </div>
        <div class="jumpLink" v-show="isLoginOrUniportal == 'true'">
          <span @click="toMeServiceUrl">{{ $t('freeLogin') }}</span>
        </div>
        <div
          class="modify_url"
          v-if="false"
        >
          <router-link
            :to="{name:'resetPasswordW3'}"
            class="forgetPwd"
          >{{$t('forgetPwd')}}</router-link>
          <router-link
            :to="{name:'updatePasswordW3'}"
            class="updatePwd"
          >{{$t('modifyPwd')}}</router-link>
        </div>
        <div class="hwLogin_url" v-show="isLoginOrUniportal == 'false'">
          <img
            src="../../assets/img/hwlogo-mobile.png"
            @click="hwLogin"
            class="hwLogin"
          >

        </div>
        <div class="hwLogin_url_mobile" v-show="isLoginOrUniportal == 'false'">
          <span>{{$t('warmTips')}}</span>
          <a
            href="javascript:void(0)"
            @click="hwLogin"
            class="hwLogin"
          >{{$t('hwAccount')}}</a>
          <span>{{$t('login')}}</span>
        </div>
        <div class="hwlogo-mobile" v-show="isLoginOrUniportal == 'false'">
          <div class="line">
            <span></span>
            <span>{{$t('otherLogin')}}</span>
            <span></span>
          </div>
          <img
            src="../../assets/img/hwlogo-mobile.png"
            @click="hwLogin"
          >
        </div>
      </div>
    </div>

    <aui-dialog-box
      :show-header="false"
      :visible="boxVisibility"
      @update:visible="boxVisibility = $event"
      :close-on-press-escape="false"
      width="90%"
    >
      <Certification
        :serviceUrl="serviceUrl"
        :uidProxy="uidProxy"
        :isSecond="isSecond"
        v-if="boxVisibility"
        fromUrl="w3Account"
        @handleSendCodeTips="handleSendCodeTips"
        @handleCloseCertifica="handleCloseCertifica"
      />

      <div
        class="send-info-tips"
        v-if="sendTipsInfoState"
      >
        <icon-success class="aui-svg-size icon-success success-img"></icon-success>
        <span>{{ sendTipsInfo }}</span>
      </div>
    </aui-dialog-box>
  </div>
</template>

<script>
import { Form, FormItem, Input, Button, Modal, DialogBox } from '@aurora/vue'
import ssoPwd from '@/components/form/ssoPwd'
import ssoError from '@/components/form/ssoError'
import ssoImageCode from '@/components/form/ssoImageCode'
import Certification from '@/views/certification/Certification'
import { getimgCodeUrl, w3login, checklogin, meServerUrlFn, getSpesInfo, xGateGetSpesInfo, spesLogin} from '@/api'
import {
  getQueryValue,
  isMobile,
  checkUserRange,
  checkUserRangeCatch,
  getFpData,
  getUrlStr,
} from '@/utils/utils'
import { IconSuccess } from '@aurora/vue-icon'
import axios from 'axios'
import qs from 'qs'

export default {
  inject: ['reload'],
  props: {
    encryptedPasswordSwitch: {
      type: String,
      default: ''
    },
  },
  data() {
    const vm = this
    const accountBlur = (rule, value, callback) => {
      vm.userShow = true
      if (vm.formData.userId === '') {
        vm.isUserId = false
        callback()
      } else if (vm.formData.userId.length > 60) {
        vm.message = vm.$t('accountTip')
        vm.isUserId = true
        callback(new Error(' '))
      } else {
        vm.isUserId = false
        vm.userShow = false
        vm.message = ''
        callback()
      }
    }
    const passwordBlur = (rule, value, callback) => {
      vm.pwdShow = true
      if (vm.formData.password === '') {
        vm.isUserId = false
        callback()
      } else if (vm.formData.password.length < 8 || vm.formData.password.length > 60) {
        vm.isUserId = true
        vm.message = vm.$t('pwdTip')
        callback(new Error(' '))
      } else {
        vm.isUserId = false
        vm.pwdShow = false
        vm.message = ''
        callback()
      }
    }
    const passwordKeyUp = function(rule, value, callback) {
      if (value.match(/[^\x00-\xff]/gi)) {
        // 密码中不能包含中文或其他多字节字符
        vm.formData.password = ''
        vm.pwdShow = true
      } else if (vm.formData.password.length > 7 && vm.formData.password.length < 60) {
        vm.pwdShow = false
      }
      callback()
    }
    const imgCodeBlur = (rule, value, callback) => {
      if (vm.formData.imageCode === '') {
        vm.isUserId = true
        vm.message = vm.$t('codeImageEmptyTip')
        callback(new Error(' '))
      } else {
        vm.message = ''
        callback()
      }
    }
    return {
      formData: {
        userId: '',
        password: '',
        imageCode: ''
      },
      rules: {
        users: [{ validator: accountBlur, trigger: 'blur' }],
        password: [{ validator: passwordBlur, trigger: 'blur' }, { validator: passwordKeyUp, trigger: 'change' }],
        imageCode: [{ validator: imgCodeBlur, trigger: 'blur' }]
      },
      isSubmit: false,
      src: '',
      message: '',
      lockedTime: 0,
      failLogonCount: 0,
      isUserId: false,
      imgCodeFlag: false,
      pwdShow: true,
      userShow: true,
      serviceUrl: '',
      boxVisibility: false,
      uidProxy: '',
      isSecond: false,
      sendTipsInfoState: false,
      sendTipsInfo: '',
      tenantId: '',
      isLoginOrUniportal: process.env.VUE_APP_ISINTRA,
      isProduction: true,
      spesLogin: {
        msgResources: {},
        spesData: {},
        timer: null,
        spesInfo: {}
      },
      freeLoginflag: true,
      isShowLogin: false,
    }
  },
  components: {
    AuiForm: Form,
    AuiFormItem: FormItem,
    AuiInput: Input,
    AuiButton: Button,
    AuiDialogBox: DialogBox,
    IconSuccess: IconSuccess(),
    ssoPwd,
    ssoImageCode,
    ssoError,
    Certification
  },
  computed: {
    loginFailCounter: function() {
      return this.$t('accountErrpr') + ' ' + this.failLogonCount + ' ' + this.$t('times')
    },
    lockedTimeCounter: function() {
      return this.$t('errorTimes') + ' ' + this.lockedTime + ' ' + this.$t('Seconds')
    },
    webError: function() {
      if (this.failLogonCount) {
        return this.loginFailCounter
      } else if (this.lockedTime) {
        return this.lockedTimeCounter
      } else if (this.message) {
        return this.message
      }
      return ''
    }
  },
  created() {
    if (localStorage.getItem('casLoginType') && localStorage.getItem('casLoginType') === 'w3') {
      localStorage.removeItem('casLoginType')
    }

    setTimeout(() => {
      this.checkloginFn();
    }, 1000)

    // 按下回车执行登录按钮点击事件
    var _self = this
    document.onkeydown = function(e) {
      var key = window.event.keyCode
      if (key === 13) {
        _self.submit()
      }
    }
  },
  mounted() {
    if (this.$route.query.service) {
      this.serviceUrl = getQueryValue('service');
      this.tenantId = this.$route.query.tenantId
    }
    getimgCodeUrl().then(data => {
      this.src = data
    })
  },
  methods: {
    freeLogin(fingerPrint) {
      let that = this
      getSpesInfo().then(res => {
        let postData = {
          version: 'v2',
          certificate: res.certificate,
          sTimeStamp: res.stimeStamp
        }
        axios.post('https://localhost:18080/getSpesInfo', qs.stringify(postData), {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
        }).then(item => {
          if (item.data) {
            var keysets = item.data.split("&");
            for (var i = 0; i < keysets.length; i++) {
              var keyValueStr = keysets[i];
              var splitIndex = keyValueStr.indexOf("=", 0);
              var spesKey = keyValueStr.substr(0, splitIndex);
              that.spesLogin.spesData[spesKey] = keyValueStr.substr(splitIndex + 1);
            }
            if (res.spnego == '2') {
              return;
            }
            if (res.spesLoginSwitch == 'off') {
              return;
            }
            var enablePwFree = that.spesLogin.spesData.enablePwFree;
            if (enablePwFree !== null && enablePwFree !== "" && enablePwFree.indexOf("w3login") >= 0) {
              let requestData = {
                version: 'v2',
                key: that.spesLogin.spesData.key,
                content: that.spesLogin.spesData.content,
                fingerPrint: fingerPrint
              }
              that.spesLoginFn(requestData)
            }
          }
        })
      })
    },
    spesLoginFn(requestData, fingerPrint) {
      let that = this;
      spesLogin(requestData).then(result => {
        let jumpUrl = result.authResultResp && result.authResultResp.callbackUrl || ''
        if (result.resultType == '0') {
          that.checkloginFn(true, jumpUrl);
        } else if (result.resultType == '10' || result.resultType == '11') {
          // 二次认证和风控认证
          const state = result.authResultResp.accountInfo.triggeringAuth === 1;
          if (isMobile()) {
            that.boxVisibility = true;
            that.uidProxy = result.authResultResp.accountInfo.uid;
            that.isSecond = state;
            return;
          }
          that.gotoSecond(result.authResultResp.accountInfo.uid, state);
        } else {
          Modal.message({
            message: that.$t('freeLoginFailed'),
            status: 'error',
            duration: '1500'
          });
        }
      })
    },
    toMeServiceUrl() {
      let environment = process.env.NODE_ENV
      let url = ''
      if (environment == 'production') {
        url = 'https://his.huawei.com/me/#/Home'
      } else {
        url = 'https://his-beta.huawei.com/me/#/Home?minisso=1'
      }
      window.open(url, '_blank')
    },
    checkloginFn(freeLogin, jumpUrl) {
      let fp = getFpData();
      fp = freeLogin ? {} : fp;
      checklogin({ fingerPrint: fp }).then(res => {
        // 是否进入到二次认证
        if (res.callbackUrl && res.callbackUrl.indexOf("#/account?") < 0) {
          window.localStorage && window.localStorage.setItem('hisPage', 'w3');
          location.href = res.callbackUrl;
          return;
        }
        if (freeLogin && jumpUrl && jumpUrl.indexOf("#/account?") < 0) {
          window.localStorage && window.localStorage.setItem('hisPage', 'w3');
          location.href = jumpUrl;
          return;
        }
        if (res.accountInfo && this.checkTriggeringAuth(res.accountInfo.triggeringAuth)) {
          const { triggeringAuth, uid } = res.accountInfo;
          this.checkSecond(triggeringAuth, res.callbackUrl, uid);
        }
        if (res.callbackUrl && res.callbackUrl.indexOf("#/account?") > -1 && this.freeLoginflag) {
          this.freeLoginflag = false;
          // 免密登录
          if (this.isLoginOrUniportal == 'true') {
            this.freeLogin(fp)
          }
        }
        if (res.errorCode) {
          checkUserRangeCatch(res, this);
        }
        if (!res.errorCode && !res.accountInfo) {
          this.isShowLogin = true;
        }
      }).catch(() => {
        this.isShowLogin = true;
      })
    },
    hwLogin() {
      localStorage.setItem('hisPage', 'hw')
      location.reload()
    },
    recode() {
      this.formData.imageCode = ''
      getimgCodeUrl().then(data => {
        this.src = data
      })
    },
    closeTip() {
      this.lockedTime = 0
      this.failLogonCount = 0
      this.message = ''
      this.isUserId = false
    },
    // 登录密码转byte字符串方法
    toByte(str){
      let bytes = new Array();
      let len, c;
      len = str.length;
      for (let i = 0; i < len; i++) {
        c = str.charCodeAt(i);
        if (c >= 0x010000 && c <= 0x10FFFF) {
          bytes.push(((c >> 18) & 0x07) | 0xF0);
          bytes.push(((c >> 12) & 0x3F) | 0x80);
          bytes.push(((c >> 6) & 0x3F) | 0x80);
          bytes.push((c & 0x3F) | 0x80);
        } else if (c >= 0x000800 && c <= 0x00FFFF) {
          bytes.push(((c >> 12) & 0x0F) | 0xE0);
          bytes.push(((c >> 6) & 0x3F) | 0x80);
          bytes.push((c & 0x3F) | 0x80);
        } else if (c >= 0x000080 && c <= 0x0007FF) {
          bytes.push(((c >> 6) & 0x1F) | 0xC0);
          bytes.push((c & 0x3F) | 0x80);
        } else {
          bytes.push(c & 0xFF);
        }
      }
      return bytes+'';
    },
    submit() {
      const self = this
      const loginName = self.formData.userId
      self.isSubmit = true
      self.closeTip()
      self.isUserId = false
      this.$refs.form.validate(valid => {
        if (valid) {
          let fp = getFpData();
          const spesDataVal = self.spesLogin.spesData;
          const param = {
            password: this.encryptedPasswordSwitch==='on' ? this.toByte(self.formData.password):self.formData.password,
            account: loginName,
            service: getQueryValue('service'),
            fingerPrint: fp,
            spesLoginReq: {
              version: spesDataVal.version ? spesDataVal.version: '',
              key: spesDataVal.key ? spesDataVal.key: '',
              content: spesDataVal.content ? spesDataVal.content: ''
            },
            encryptedPasswordSwitch:this.encryptedPasswordSwitch
          }
          w3login(param).then(res => {
            if (res.accountInfo && res.accountInfo.pwdRemainDays) {
              const query = {
                state: 1,
                uid: this.formData.userId,
                service: this.serviceUrl,
                deadTime: res.accountInfo.pwdRemainDays,
                callbackUrl: res.callbackUrl,
                tenantId: this.tenantId
              }
              const queryString = getUrlStr(query);
              this.$router.push(`/pwdTips?${queryString}`)
              return;
            }
            // 是否进入到二次认证
            if (res.accountInfo && this.checkTriggeringAuth(res.accountInfo.triggeringAuth)) {
              const { triggeringAuth, uid } = res.accountInfo;
              this.checkSecond(triggeringAuth, res.callbackUrl, uid);
              return;
            }
            if (!res.errorCode) return
            switch (res.errorCode) {
              case 'CAS-02-00008':
                // 帐号已被锁定
                self.failLockCommon(res)
                break;
              case 'CAS-02-00012':
                this.gotoPwdTips();
                // 密码已过期
                break;
              case 'CAS-02-00011':
                // 账号或密码错误
                self.failLockCommon(res)
                // 暂时不显示验证吗self.imgCodeFlag = true
                break;
              case 'HWIDLOGIN_000005':
                this.gotoPwdTips();
                // 密码已过期
                break;
              case 'CAS-03-00006':
                self.isUserId = true
                self.message = self.$t('serviceNotFound')
                // 服务未注册
                break;
              default:
                self.isUserId = true
              // 没有覆盖的情况self.message = res.errorCode

            }
          })
        }
      })
    },
    gotoSecond(uid, isSecond) {
      localStorage.setItem('casLoginType', 'w3');
      // urlType是w3账户登录
      let query = { urlType: 2, uid, isSecond, tenantId: this.tenantId, service: this.serviceUrl };
      const queryString = getUrlStr(query);
      this.$router.push(`/certification?${queryString}`)
    },
    checkSecond(triggeringAuthVal, callbackUrl, uid) {
      if (triggeringAuthVal === 0) {
        // 放行
        if (callbackUrl) {
          window.localStorage && window.localStorage.setItem('hisPage', 'w3');
          checkUserRange(callbackUrl, this, '');
        }
      } else if (triggeringAuthVal === 1 || triggeringAuthVal === 2) {
        // 二次认证和风控认证
        const state = triggeringAuthVal === 1;
        if (isMobile()) {
          this.boxVisibility = true;
          this.uidProxy = uid;
          this.isSecond = state;
          return;
        }
        this.gotoSecond(uid, state);
      } else if (triggeringAuthVal === 3 || triggeringAuthVal === 4) {
        // 应用侧拒绝和风控拒绝
        Modal.message({
          message: this.$t('loginRefused'),
          status: 'error',
          duration: '1500'
        });
      }
    },
    handleCloseCertifica() {
      this.boxVisibility = false;
    },
    handleSendCodeTips(data) {
      const _this = this;
      _this.sendTipsInfoState = true;
      if (data.type === 'sms') {
        _this.sendTipsInfo = _this.$t('sendSmsTips1') + data.sendInfo + _this.$t('sendSmsTips2');
      } else if (data.type === 'email') {
        _this.sendTipsInfo = _this.$t('sendEmailTips1') + data.sendInfo + _this.$t('sendSmsTips2');
      }

      setTimeout(function() {
        _this.sendTipsInfoState = false;
        _this.sendTipsInfo = '';
      }, 1500);
    },
    failLockCommon(res) {
      if (res.errorData.lockedTime > 0) {
        this.failLogonCount = 0
        this.lockedTime = Math.ceil(res.errorData.lockedTime / 1000)
        this.timerAction()
      } else {
        this.failLogonCount = 5 - res.errorData.failLogonCount || 5
      }
    },
    timerAction() {
      const self = this
      clearInterval(self.threetimer)
      self.threetimer = null
      self.threetimer = setInterval(() => {
        if (self.lockedTime <= 0) {
          clearInterval(self.threetimer)
          self.lockedTime = 0
          return
        }
        self.lockedTime--
      }, 1000)
    },
    // 修改密码
    updatePassword() {
      if(this.isLoginOrUniportal == 'false') {
        let query = { type: 1, service: this.serviceUrl, tenantId: this.tenantId, urlType: 2 };
        if (this.formData.userId) {
          query.uid = this.formData.userId;
        }
        const queryString = getUrlStr(query);
        this.$router.push(`/resetPwd?${queryString}`)
      } else {
        this.meServerUrlFunc('intra_modify_password_url')
      }
    },
    // 内网跳转到me服务
    meServerUrlFunc(type) {
      meServerUrlFn({param: type}).then(res => {
        if(res) {
          location.href = res
        }
      })
    },
    // 忘记密码
    forgetPassword() {
      if(this.isLoginOrUniportal == 'false') {
        let query = { type: 2, service: this.serviceUrl, tenantId: this.tenantId, urlType: 2 };
        if (this.formData.userId) {
          query.uid = this.formData.userId;
        }
        const queryString = getUrlStr(query);
        this.$router.push(`/resetPwd?${queryString}`)
      } else {
        this.meServerUrlFunc('intra_reset_password_url')
      }
    },
    gotoPwdTips() {
      const query = {
        state: 2,
        uid: this.formData.userId,
        service: this.serviceUrl,
        tenantId: this.tenantId
      }
      const queryString = getUrlStr(query);
      this.$router.push(`/pwdTips?${queryString}`)
    },
    checkTriggeringAuth(data) {
      if (!data && data != 0) {
        return false;
      }
      return true;
    },
  }
}
</script>
<style lang="scss" scoped>
.visibulity {
  height: 80px;
}
.login-main {
  width: 558px;
  margin: 0 auto;
  height: auto;
  background: #fff;
  padding: 64px 63px 101px 63px;
  box-sizing: border-box;
  .hwidsdk_mudule {
    height: 300px;
    text-align: center;
  }
  .w3Title {
    text-align: center;
    margin-bottom: 20px;
    span {
      display: inline-block;
      width: 285px;
      height: 42px;
      font-size: 32px;
      font-family: Microsoft Yahei, STHeiti, Simsun, STSong, Helvetica Neue, Helvetica, Arial, sans-serif;
      text-align: center;
      color: #191919;
      line-height: 40px;
    }
  }
  .w3Container {
    .web_show {
      margin-bottom: 8px;
    }
    .aui-btn-full {
      height: 48px;
    }
    .modify_url {
      color: #808080;
      display: flex;
      justify-content: center;
      align-items: center;
      .forgetPwd {
        border-right: 1px solid #e5e5e5;
        margin-right: 11px;
        padding-right: 11px;
      }
    }
    .hwLogin_url {
      display: flex;
      justify-content: center;
      margin-top: 32px;
    }
    .hwLogin_url_mobile,
    .hwlogo-mobile {
      display: none;
    }
    .hwLogin {
      cursor: pointer;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      text-align: center;
      text-decoration: none !important;
      color: #007dff !important;
    }
    .forgetPwd,
    .updatePwd {
      cursor: pointer;
      font-size: 16px;
      font-family: Source Han Sans CN, Source Han Sans CN-Medium;
      font-weight: 500;
      text-align: center;
      text-decoration: none !important;
      color: #808080;
      &:hover {
        color: #007dff;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .login-main {
    width: 448px;
    padding: 44px 36px 70px;
    .w3Container {
      .aui-btn-full {
        height: 42px;
      }
    }
    .w3Title {
      text-align: center;
      margin-bottom: 20px;
      span {
        font-size: 26px;
      }
    }
  }
  :deep(input.aui-input__inner) {
    height: 39px;
  }
  .w3Title {
    text-align: center;
    margin-bottom: 20px;
    span {
      font-size: 26px;
    }
  }
}
@media screen and (max-width: 767px) {
  .login-main {
    width: auto;
    margin: 0 auto;
    height: auto;
    background: #fff;
    padding: 32px 24px 10px 24px;
    .w3Title {
      margin-bottom: 48px;
      span {
        height: 32px;
        font-size: 24px;
        font-family: Source Han Sans CN, Source Han Sans CN-Medium;
        font-weight: 500;
        text-align: center;
        color: #000000;
        line-height: 32px;
      }
    }
    .w3Container {
      .hwLogin_url {
        display: none;
      }
      .hwLogin_url_mobile {
        height: 36px;
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
        line-height: 18px;
        margin-top: 32px;
        .hwLogin {
          font-size: 12px;
        }
      }
      .hwlogo-mobile {
        display: block;
        text-align: center;
        .line {
          display: flex;
          span:nth-child(odd) {
            display: inline-block;
            border-top: 1px solid #ccc;
            margin-top: 7px;
            flex: auto;
          }
          span:nth-child(2) {
            height: 14px;
            opacity: 0.6;
            font-size: 12px;
            font-family: HarmonyHeiTi, HarmonyHeiTi-Regular;
            font-weight: 400;
            text-align: center;
            color: #1a1a1a;
            line-height: 14px;
            margin: 0 8px;
          }
        }
        img {
          margin-top: 14px;
          cursor: pointer;
        }
      }
      .aui-btn-full {
        margin-top: 20px;
      }
      .modify_url {
        margin-top: 30px;
        margin-bottom: 20px;
        .forgetPwd,
        .updatePwd {
          color: #007dff;
        }
      }
      .jumpLink {
        margin-bottom: 20px;
      }
    }
  }
  :deep(input.aui-input__inner) {
    height: 36px;
  }
  .visibulity {
    height: 40px;
  }
  :deep(.aui-dialog-box) {
    position: absolute;
    width: 90% !important;
    left: 0 !important;
    right: 0 !important;
    top: 15% !important;
    margin: auto;
    border-radius: 6px;
    overflow: visible;
  }
  :deep(.aui-dialog-box .aui-dialog-box__body) {
    padding: 0;

    .send-info-tips {
      position: absolute;
      bottom: -65px;
      width: 100%;
      background-color: #fff;
      border-radius: 6px;
      box-sizing: border-box;
      padding: 10px 15px;

      .success-img {
        fill: #52c41a;
        margin-right: 5px;
      }

      > span {
        font-size: 12px;
      }
    }
  }
}
.jumpLink {
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
  span {
    color: #1890ff;
    cursor: pointer;
  }
}
</style>
