<template>
  <div class="welcome-box">
    <div class="visibulity"></div>
    <div class="welcome-img">
      <img
        class="exclamationPoint"
        ondragstart="return false"
        src="../../assets/img/icon-waiting.png"
      />
      <h2>welcome</h2>
      <span>loading...</span>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.visibulity {
  height: 40px;
}
.welcome-img {
  width: 558px;
  height: 513px;
  background: #ffffff;
  margin: 0 auto;
  text-align: center;
  padding: 50px 0;
}
</style>
