<template>
  <div v-show="isShowLogin">
    <div class="visibulity"></div>
    <div
      class="hwidsdk_header"
      v-if="false"
    >
      <img
        src="../../assets/img/hwlogo-mobile.png"
        alt=""
      />
      <div class="hwidsdk_header_title">{{ $t('hwAccount') }}</div>
    </div>
    <div
      class="login-main"
      v-if="status"
    >
      <div class="cas-js-sdk-wap-page">
        <!-- sdk container -->
        <div class="hwidsdk_mudule"></div>
      </div>
      <div
        class="otherLogin"
        v-show="otherFlag"
      >
        <div class="line">
          <span></span>
          <span>{{ $t('otherLogin') }}</span>
          <span></span>
        </div>
        <div class="logo">
          <img
            src="../../assets/img/w3.png"
            alt=""
            @click="targetPage('w3')"
            v-if="true"
          />
          <p
            class="uniportal-logo"
            v-if="false"
            @click="targetPage('uniportal')"
          >{{ $t('uniportalAccountLogin') }}</p>
          <template
            v-for="item in thirdLoginList"
            :key="item.name"
          >
            <a
              :href="item.fullUrl"
              class="hwid-icon-ic_third_qq"
              v-if="item.name == 'qq'"
            > </a>
            <a
              :href="item.fullUrl"
              class="hwid-icon-ic_third_weixin"
              v-if="item.name == 'weixin'"
            > </a>
          </template>
        </div>
        <div class="description">
          <p>
            {{ this.$t('directions') + ' ' }}<a
              href="javascript:;"
              @click="underMore"
            >{{ this.$t('underMore') }}</a>
          </p>
        </div>
      </div>
      <img
        src="../../assets/img/loading_hwid.gif"
        alt=""
        class="loading_hwid"
        v-if="hwidSuccess"
      />
    </div>
    <div
      class="error_con"
      v-else
    >
      <img
        src="../../assets/img/hwjsdkNull.svg"
        alt=""
      />
      <div class="text">
        <p>
          {{ $t('directions1') }}<a href="#">{{ ' ' + $t('links') + ' ' }}</a>{{ $t('directions2') }}
        </p>
      </div>
    </div>
    <div class="visibulity-bottom"></div>
  </div>
</template>

<script>
import axios from 'axios'
import { 
  getQueryValue,
  getQueryVal,
  checkUserRangeCatch,
  getFpData,
  getUrlStr
} from '@/utils/utils'
import { Loading } from '@aurora/vue'
import { agreementUrl } from '@/assets/js'
import { checklogin } from '@/api'
export default {
  data() {
    return {
      status: true,
      loading: '',
      otherFlag: false,
      thirdLoginList: [],
      hwidSuccess: true,
      serviceUrl: '',
      isShowLogin: false,
    }
  },
  components: {},
  computed: {},
  created() {
    this.getJssdk();
    setTimeout(() => {
      this.checkloginFn();
    }, 100)
  },
  mounted() {
    if (this.$route.query.service) {
      this.serviceUrl = this.$route.query.service;
    }
  },
  methods: {
    checkloginFn() {
      let fp = getFpData();
      checklogin({ fingerPrint: fp }).then((res) => {
        // 是否进入到二次认证
        if (res.callbackUrl && res.callbackUrl.indexOf("#/account?") < 0) {
          window.localStorage && window.localStorage.setItem('hisPage', 'hw');
          location.href = res.callbackUrl;
          return;
        }
        if (res.accountInfo && this.checkTriggeringAuth(res.accountInfo.triggeringAuth)) {
          const { triggeringAuth, uid } = res.accountInfo;
          this.checkSecond(triggeringAuth, res.callbackUrl, uid);
        }
        if (res.errorCode) {
          checkUserRangeCatch(res, this);
        }
        if (!res.errorCode && !res.accountInfo) {
          this.isShowLogin = true;
        }
      }).catch(() => {
        this.isShowLogin = true;
      })
    },
    checkTriggeringAuth(data) {
      if (!data && data != 0) {
        return false;
      }
      return true;
    },
    underMore() {
      const locale = this.$i18n.locale
      window.open(
        agreementUrl + '?language=' + locale + '&code=CN&version=20200901&branchid=0&isNewCAS=1&themeName=&anchorType='
      )
    },
    getJssdk: function() {
      // 获取用户信息
      const _this = this
      const isMobile = _this.isMobile()
      const invoLang = this.$i18n.locale
      const service = getQueryValue('service')
      const baseUrl = process.env.VUE_APP_BASE_API
      _this.showErrorInfo('')
      axios
        .get(
          baseUrl + 
          '/hwid/login/jssdk?lang=' +
          invoLang +
          '&mobile=' +
          isMobile +
          '&service=' +
          service +
          '&tenantPublicId=' + getQueryVal('tenantId') +
          '&loginUrl=' + encodeURIComponent(window.location.href)
        )
        .then(function(res) {
          _this.hwidSuccess = false
          if (res.data && res.data.hwidConfig) {
            _this.addScript(res.data.jsSdkUrl, function(responseText) {
              const sdkConfig = _this.getHwidSdkConfig(res.data)
              hwidSDK.init(sdkConfig)
            })
            if (isMobile) {
              _this.thirdLoginList = res.data.thirdLoginList
            }
          } else {
            _this.showErrorInfo(res.data.errorInfo)
          }
          _this.hwidSuccess = false
        })
        .catch(function() {
          // 失败显示页
          _this.hwidSuccess = false
          _this.status = false
        })
    },
    GetHttpRequest: function() {
      if (window.XMLHttpRequest) {
        return new XMLHttpRequest()
      } else if (window.ActiveXObject) {
        // IE
        return new ActiveXObject('MsXml2.XmlHttp')
      }
      return new XMLHttpRequest()
    },
    // 请求js文件
    loadScript: function(url, callback) {
      var oXmlHttp = this.GetHttpRequest()
      oXmlHttp.onreadystatechange = function() {
        if (oXmlHttp.readyState == 4) {
          if (oXmlHttp.status == 200 || oXmlHttp.status == 304) {
            callback(oXmlHttp.responseText)
          } else {
            callback('XML request error: ' + oXmlHttp.statusText + ' (' + oXmlHttp.status + ')')
          }
        }
      }
      // 第三个参数false同步请求
      oXmlHttp.open('GET', url, false)
      oXmlHttp.send(null)
    },
    // 动态添加js文件
    addScript(url, callback) {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      // 请求完成回调
      if (script.readyState) {
        // IE
        script.onreadystatechange = function() {
          if (script.readyState === 'loaded' || script.readyState === 'complete') {
            script.onreadystatechange = null
            callback()
          }
        }
      } else {
        // Others
        script.onload = function() {
          callback()
        }
      }
      script.src = url
      document.getElementsByTagName('head')[0].appendChild(script)
    },
    showErrorInfo: function(text) {
      try {
        // 赋值
        $('.hwidsdk_login_mudule').innerText = text
      } catch (e) { }
    },
    getHwidSdkConfig: function(sdkInfo) {
      const _this = this
      var sdkConfig = {
        pageTokenKey: sdkInfo.pageTokenKey,
        pageToken: sdkInfo.pageToken,
        hwidConfig: sdkInfo.hwidConfig
      }
      sdkConfig.businessConf = {
        showType: 'page',
        // 标识JS-SDK的显示类型是WAP/WEB类型，枚举值：手机浏览器端:mobileWap,PC浏览器端: pcWeb
        clientType: _this.isMobile() ? 'mobileWap' : 'pcWeb',
        // 是否打开短信登陆,默认是开启，非必填
        isOpenSMSLogin: 'true',
        isOpenJsRemoteLogin: (sdkInfo.autoLogin && sdkInfo.autoLogin === true) ? true : false,
        callbackFun: function() {
          _this.otherFlag = true
        },
        mountedCallback: function() {
          _this.otherFlag = true
        },
        overtimeCallbackFun: function() {
          return null
        }
      }
      return sdkConfig
    },
    isMobile: function() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) || window.innerWidth <= 500
    },
    targetPage(name) {
      const query = { service: getQueryValue('service', true), tenantId: getQueryVal('tenantId') };
      const queryString = getUrlStr(query);
      if (name === 'w3') {
        window.localStorage && window.localStorage.setItem('hisPage', 'w3');
        this.$router.push(`/w3Account?${queryString}`)
      } else if (name === 'uniportal') {
        this.$router.push(`/uniportalAccount?${queryString}`)
      }
    },
    addDiv() {
      let div = window.document.createElement('div')
      div.setAttribute('class', 'thirdLogin-logo')
      let value = 'w3'
      div.innerHTML = "<span class='addSpan'>" + value + '</span>'
      document.getElementsByClassName('hwid-third-login-list')[0].appendChild(div)
      document.getElementsByClassName('addSpan')[0].addEventListener('click', function() {
        this.$router.push(`/w3Account?service=${getQueryValue('service')}`)
      })
    },
    gotoSecond(uid, isSecond) {
      const query = { service: getQueryValue('service'), uid, isSecond, tenantId: getQueryVal('tenantId') };
      const queryString = getUrlStr(query);
      this.$router.push(`/certification?${queryString}`)
    },
    checkSecond(triggeringAuthVal, callbackUrl, uid) {
      if (triggeringAuthVal === 0) {
        // 放行
        if (callbackUrl) {
          window.localStorage && window.localStorage.setItem('hisPage', 'w3');
          location.href = callbackUrl;
        }
      } else if (triggeringAuthVal === 1 || triggeringAuthVal === 2) {
        // 二次认证和风控认证
        const state = triggeringAuthVal === 1;
        this.gotoSecond(uid, state);
      } else if (triggeringAuthVal === 3 || triggeringAuthVal === 4) {
        // 应用侧拒绝和风控拒绝
        Modal.message({
          message: this.$t('loginRefused'),
          status: 'error',
          duration: '1500'
        });
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.visibulity {
  height: 80px;
}
.visibulity-bottom {
  height: 80px;
}
.error_con {
  width: 980px;
  height: 631px;
  background: #ffffff;
  margin: 0 auto;
  text-align: center;
  img {
    margin-top: 94px;
  }
  .text {
    display: flex;
    justify-content: center;
    p {
      width: 412px;
      height: 43px;
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: undefined;
      line-height: 20px;
      a {
        color: #1890ff !important;
        cursor: pointer;
      }
    }
  }
}
.hwidsdk_header {
  display: none;
}
.login-main {
  width: 35rem;
  margin: 0 auto;
  height: auto;
  padding: 0 63px 60px;
  position: relative;
  background: #fff;
  box-sizing: border-box;
  .hwidsdk_mudule {
    height: 300px;
    text-align: center;
  }
  :deep(.hwid-root) {
    z-index: 0 !important;
  }
  :deep(.hwid-root .hwid-login-root .hwid-otherlink) {
    padding-top: 1.5rem;
  }
  :deep(.hwid-root .hwid-login-root.hwid-login-root-iframe .hwid-common-content) {
    min-height: 37rem !important;
  }
  :deep(.hwid-root .hwid-login-root .hwid-header .hwid-header-qrcode-box) {
    display: none;
  }
  :deep(.hwid-root .hwid-login-root .hwid-header-tab) {
    padding-top: 50px;
  }
  :deep(.hwid-root .hwid-pwdlogin-root .hwid-login-btn-wrap .hwid-login-btn) {
    width: 100%;
    padding: 12px 0;
  }
  :deep(.hwid-root .button-base-box),
  .normalBtn {
    width: 100%;
  }
  .otherLogin {
    margin-top: 40px;
    .line {
      display: flex;
      span:nth-child(odd) {
        display: inline-block;
        border-top: 1px solid #ccc;
        margin-top: 7px;
        flex: auto;
      }
      span:nth-child(2) {
        height: 14px;
        opacity: 0.6;
        font-size: 12px;
        font-family: HarmonyHeiTi, HarmonyHeiTi-Regular;
        font-weight: 400;
        text-align: center;
        color: #1a1a1a;
        line-height: 14px;
        margin: 0 8px;
      }
    }
    .description {
      height: 40px;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
      line-height: 20px;
      a {
        color: #007dff !important;
      }
    }
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      line-height: 60px;
      margin-top: 10px;
      img {
        width: 29px;
        margin: 0 12px;
        cursor: pointer;
      }
      img:hover {
        width: 35px;
      }
      .uniportal-logo {
        cursor: pointer;
        color: #007dff;
      }
    }
  }
  .loading_hwid {
    position: absolute;
    top: 50%;
    transform: translatex(-48px) translatey(-48px);
    left: 50%;
  }
}
@media screen and (max-width: 1440px) {
  .login-main {
    width: 28rem;
  }
  .login-main :deep(.hwid-root .hwid-login-root.hwid-login-root-iframe .hwid-common-content) {
    min-height: 30rem !important;
  }
}
@media screen and (max-width: 767px) {
  .visibulity {
    height: 72px;
  }
  .visibulity-bottom {
    height: 30px;
  }
  .hwidsdk_header {
    display: none;
    text-align: center;
    .hwidsdk_header_title {
      height: 24px;
      line-height: 24px;
      font-size: 24px;
      font-family: Source Han Sans CN, Source Han Sans CN-Regular;
      font-weight: 400;
      color: #000000;
      margin-top: 16px;
    }
  }
  .login-main {
    width: auto;
    padding: 0 24px 10px 24px;
    .otherLogin {
      margin-top: 40px;
      padding: unset;
      .description {
        height: auto;
      }
    }
    :deep(.hwid-root .hwid-login-root.hwid-login-root-iframe .hwid-login-container) {
      margin: 0 auto;
    }
    :deep(.hwid-root .hwid-login-root .hwid-header-tab) {
      padding-top: 0px;
    }
  }
  .hwid-icon-ic_third_qq {
    width: 29px;
    height: 29px;
    margin: 0 12px;
    background-image: url('../../assets/img/qq.png') !important;
  }
  .hwid-icon-ic_third_weixin {
    width: 29px;
    height: 29px;
    margin: 0 12px;
    background-image: url('../../assets/img/weixin.png') !important;
  }
  .error_con {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
    }
    .text {
      p {
        width: 100;
        padding: 0 24px;
      }
    }
  }
}
</style>
