 <template>
  <Certification :serviceUrl="serviceUrl" :uidProxy="uidProxy" :isSecond="isSecond" :fromUrl="fromUrl" />
</template>

<script>
import { getQueryValue } from '@/utils/utils'
import Certification from '@/views/certification/Certification';

export default {
  data() {
    return {
      serviceUrl: '',
      uidProxy: '',
      isSecond: false, 
      fromUrl: '',
    }
  },
  components: {
    Certification
  },
  mounted() {
    if (localStorage.getItem('casLoginType')) {
      this.fromUrl = localStorage.getItem('casLoginType') === 'w3' ? 'w3Account' : 'uniportalAccount';
    }
    if (this.$route.query.service) {
      this.serviceUrl = getQueryValue('service');
    }
    if (this.$route.query.uid) {
      this.uidProxy = this.$route.query.uid;
    }
    if (this.$route.query.isSecond && this.$route.query.isSecond.toString() === 'true') {
      this.isSecond = true;
    } else {
      this.isSecond = false;
    }
  },
}
</script>
