<template>
  <div class="pwd-code-content">
    <div class="pwd-tips">{{ $t('pwdCodeTips') }}</div>
    <div class="pwd-info">
      <span>{{ setCaptchaTips }}</span>
      <span style="word-break: break-all;">{{ contactInfo }}</span>
    </div>
    <div class="account-content">
      <aui-form
        ref="form"
        :model="formData"
        label-width="0"
        validate-type="text"
        :rules="rules"
        show-message
        @submit.prevent
      >
        <aui-form-item
          label=""
          class="pwd-account"
          prop="code"
        >
          <aui-input
            v-model.trim="formData.code"
            :placeholder="contactPlaceholder"
            :disabled="inputDisabled"
          >
            <template #suffix>
              <template v-if="phoneCode">
                <span @click="getVerificationCode" class="pwd-code" v-show="show">
                  {{ firstGetCode ? $t('getCode') : $t('resend') }}
                </span>
                <span 
                  class="pwd-code pwd-again-code" 
                  v-show="!show" 
                  v-html="$t('againCode', {time: count})">
                </span>
              </template>
              <template v-else>
                <span @click="getVerificationCode" class="pwd-code" v-show="showEmailTime">
                  {{ firstGetEmailCode ? $t('getCode') : $t('resend') }}
                </span>
                <span 
                  class="pwd-code pwd-again-code" 
                  v-show="!showEmailTime" 
                  v-html="$t('againCode', {time: emailCount})">
                </span>
              </template>
            </template>
          </aui-input>
          <div class="account-error" v-if="errorState">{{ errorInfo }}</div>
          <div class="dialogBox" v-if="showEmailCodeTip">
            <span>{{ $t('emailSendTo') + contactInfo + $t('tenMinutes') }}</span>
          </div>
          <div class="dialogBox" v-if="showCodeTip">
            <span>{{ $t('smsSendTo') + contactInfo + $t('tenMinutes') }}</span>
          </div>
        </aui-form-item>

        <aui-form-item
          label=""
          class="change-code-content"
          v-if="showEmail && !isMobile"
        >
          <span class="change-code" @click="changeCode">
            {{ changePhoneOrEmail }}
          </span>
        </aui-form-item>

        <aui-form-item class="pwd-account-btn">
          <aui-button
            type="primary"
            class="pwd-button"
            @click="next"
            :disabled="btnState"
          >{{$t('next')}}</aui-button>
        </aui-form-item>

        <div 
          class="mobile-change-code" 
          @click="changeCode"
          v-if="isMobile && showEmail"
        >
          {{ changePhoneOrEmail }}
        </div>
      </aui-form>
    </div>
  </div>
</template>

<script>
import { Form, FormItem, Input, Button, Modal } from '@aurora/vue';
import { sendEmailCode, checkEmailCode, sendSmsCode, checkSmsCode } from '@/api';
import { getCookie, setErrorMessage } from '@/utils/utils';

export default {
  name: 'PwdCaptchaCode',
  components: {
    AuiForm: Form,
    AuiFormItem: FormItem,
    AuiInput: Input,
    AuiButton: Button,
  },
  props: {
    isMobile: Boolean,
    checkCode: Object,
  },
  data() {
    const vm = this
    const codeBlur = (rule, value, callback) => {
      if (this.errorState) {
        this.errorState = false;
      }
      this.btnState = false;
      if (!vm.formData.code) {
        this.btnState = true;
        const errorTipsMsg = this.captchaState === 1 
          ? vm.$t('smsCodeEmptyTip') 
          : vm.$t('emailCodeEmptyTip');
        callback(new Error(errorTipsMsg));
      }
      return callback();
    }
    return {
      btnState: true,
      errorState: false,
      errorInfo: '',
      captchaState: 1,
      showEmail: true,
      inputDisabled: true,
      contactInfo: '',
      phoneAndEmail: {
        phoneNumber: '',
        email: '',
      },
      formData: {
        code: '',
      },
      show: true,
      count: '',
      timer: null,
      rules: {
        code: [{ validator: codeBlur, trigger: 'change' }],
      },
      type: 0,
      firstGetCode: true,
      phoneCode: true,
      firstGetEmailCode: true,
      emailCount: 0,
      showEmailTime: true,
      emailTimer: null,
      showCodeTip: false,
      showEmailCodeTip: false,
      threetime: 3,
    };
  },
  computed: {
    setCaptchaTips() {
      return this.captchaState === 1 
        ? this.$t('pwdCodePhoneTips') 
        : this.$t('pwdCodeEmailTips') ;
    },
    contactPlaceholder() {
      return this.captchaState === 1 
        ? this.$t('smsVerification') 
        : this.$t('emailVerification') ;
    },
    changePhoneOrEmail() {
      return this.captchaState === 1 
        ? this.$t('pwdCodeChangeEmail') 
        : this.$t('pwdCodeChangePhone') ;
    },
  },
  mounted() {
    this.type = Number(this.$route.query.type);
    this.setContactInfo();
  },
  methods: {
    setContactInfo() {
      const { phoneNumber, email, callCountryCode } = this.checkCode;

      if (phoneNumber && email) {
        const areaCode = this.setCallCountryCode(callCountryCode);
        this.contactInfo = areaCode + ' ' + phoneNumber;
        this.showEmail = true;
        this.captchaState = 1;
        this.phoneAndEmail.phoneNumber = this.contactInfo;
        this.phoneAndEmail.email = email;
        this.phoneCode = true
      } else if (phoneNumber && !email) {
        const areaCode = this.setCallCountryCode(callCountryCode);
        this.contactInfo = areaCode + ' ' + phoneNumber;
        this.showEmail = false;
        this.captchaState = 1;
        this.phoneAndEmail.phoneNumber = this.contactInfo;
        this.phoneAndEmail.email = '';
        this.phoneCode = true
      } else if (email && !phoneNumber) {
        this.contactInfo = email;
        this.showEmail = false;
        this.captchaState = 2;
        this.phoneAndEmail.phoneNumber = '';
        this.phoneAndEmail.email = this.contactInfo;
        this.phoneCode = false
      }
    },
    next() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const { code } = this.formData;
          if (this.captchaState === 1) {
            this.checkSmsCodeFn(code);
          } else if (this.captchaState === 2) {
            this.checkEmailCodeFn(code);
          }
        }
      })
    },
    // 获取验证码
    getVerificationCode() {
      if (this.captchaState === 1) {
        this.sendSmsCodeFn();
      } else if (this.captchaState === 2) {
        this.sendEmailCodeFn();
      }
    },
    getCodeNum(transferType) {
      const TIME_COUNT = 60;
      if (transferType == 'phone') {
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000)
        }
      } else if (transferType == 'email') {
        if (!this.emailTimer) {
          this.emailCount = TIME_COUNT;
          this.showEmailTime = false;
          this.emailTimer = setInterval(() => {
            if (this.emailCount > 0 && this.emailCount <= TIME_COUNT) {
              this.emailCount--;
            } else {
              this.showEmailTime = true;
              clearInterval(this.emailTimer);
              this.emailTimer = null;
            }
          }, 1000)
        }
      }
    },
    // 切换校验方式
    changeCode() {
      if (this.errorState) {
        this.errorState = false;
      }
      if (this.captchaState === 1) {
        this.captchaState = 2;
        this.contactInfo = this.phoneAndEmail.email;
        this.phoneCode = false
      } else if (this.captchaState === 2) {
        this.captchaState = 1;
        this.contactInfo = this.phoneAndEmail.phoneNumber;
        this.phoneCode = true
      }
    },
    // 发送email验证码
    sendEmailCodeFn() {
      const source = this.type === 1 ? 3 : 2;
      const params = {
        uid: this.checkCode.uid,
        source,
        language: getCookie('lang').split('_')[1].toUpperCase(),
      };
      sendEmailCode(params).then(res => {
        if (res.toString() === 'true') {
          if (this.errorState) {
            this.errorState = false;
          }
          this.inputDisabled = false;
          this.getCodeNum('email');
          this.firstGetEmailCode = false;
          this.showEmailCodeTip = true
          let self = this;
          let threetimer = setInterval(function () {
            if (self.threetime == 1) {
              clearInterval(threetimer)
              self.showEmailCodeTip = false
              self.threetime = 3
            } else {
              self.threetime--
            }
          }, 1000)
        }
        
        if (!res.errorCode) return;

        if (res.errorCode === 'CAS-07-00010') {
          this.errorState = true;
          this.errorInfo = this.$t('timeOut');
        } else if (res.errorCode === 'IDC-SMS-00029') {
          this.longTime();
        } else if (['CAS-02-00006', 'CAS-02-00041'].indexOf(res.errorCode) > -1) {
          this.errorState = true;
          this.errorInfo = setErrorMessage(res.errorCode);
        } else {
          this.errorState = true;
          this.errorInfo = this.$t(res.errorCode);
        }
      });
    },
    // 校验email验证码
    checkEmailCodeFn(verifyCode) {
      const source = this.type === 1 ? 3 : 2;
      const params = {
        uid: this.checkCode.uid,
        verifyCode,
        source,
      };
      checkEmailCode(params).then(res => {
        if (res.toString() === 'true') {
          if (this.timer) {
            clearInterval(this.timer);
          }
          this.$emit('changePage', { page: 3 });
        }

        if (!res.errorCode) return;

        if (['IDC-SMS-00027', 'CAS-07-00027'].includes(res.errorCode)) {
          this.errorState = true;
          this.errorInfo = this.$t('verifyCodeError');
        } else if (res.errorCode === 'IDC-SMS-00029') {
          this.longTime();
        }
      });
    },
    // 发送手机验证码
    sendSmsCodeFn() {
      const source = this.type === 1 ? 3 : 2;
      const params = {
        uid: this.checkCode.uid,
        source,
        language: getCookie('lang').split('_')[1].toUpperCase(),
      };
      sendSmsCode(params).then(res => {
        if (res.toString() === 'true') {
          if (this.errorState) {
            this.errorState = false;
          }
          this.inputDisabled = false;
          this.getCodeNum('phone');
          this.firstGetCode = false;
          this.showCodeTip = true
          let self = this;
          let threetimer = setInterval(function () {
            if (self.threetime == 1) {
              clearInterval(threetimer)
              self.showCodeTip = false
              self.threetime = 3
            } else {
              self.threetime--
            }
          }, 1000)
        }

        if (!res.errorCode) return;

        if (res.errorCode === 'CAS-07-00010') {
          this.errorState = true;
          this.errorInfo = this.$t('timeOut');
        } else if (res.errorCode === 'IDC-SMS-00029') {
          this.longTime();
        } else if (['CAS-02-00006', 'CAS-02-00041', 'CAS-02-00043'].indexOf(res.errorCode) > -1) {
          this.errorState = true;
          this.errorInfo = setErrorMessage(res.errorCode);
        } else {
          this.errorState = true;
          this.errorInfo = this.$t(res.errorCode);
        }
      });
    },
    // 校验手机验证码  
    checkSmsCodeFn(verifyCode) {
      const source = this.type === 1 ? 3 : 2;
      const params = {
        uid: this.checkCode.uid,
        verifyCode,
        source,
      };
      checkSmsCode(params).then(res => {
        if (res.toString() === 'true') {
          if (this.timer) {
            clearInterval(this.timer);
          }
          this.$emit('changePage', { page: 3 });
        } else if (res.toString() === 'false') {
          this.errorState = true;
          this.errorInfo = this.$t('verifyCodeError');
        }

        if (!res.errorCode) return;

        if (res.errorCode === 'IDC-SMS-00029') {
          this.longTime();
        } 
      });
    },
    setCallCountryCode(code) {
      if (!code) {
        return '';
      }
      return '+' + code.replace(/\b(0+)/gi,"");
    },
    longTime() {
      Modal.message({
        message: this.$t('longTime'),
        status: 'error',
        duration: '1500'
      });
      setTimeout(() => {
        this.$emit('changePage', { page: 1 });
      }, 1700)
    },
  },
}
</script>

<style lang="scss" scoped>
@mixin btnBackground($color, $opacity) {
  :deep(.aui-button.aui-button--primary.is-disabled), 
  :deep(.aui-button.aui-button--primary.is-disabled:active), 
  :deep(.aui-button.aui-button--primary.is-disabled:focus), 
  :deep(.aui-button.aui-button--primary.is-disabled:hover) {
    border-color: $color;
    background-color: $color;
    opacity: $opacity;
  }
}

.pwd-code-content {
  .pwd-tips {
    font-size: 14px;
    color: #4a4a4a;
    margin-bottom: 12px;
  }
  .pwd-info {
    font-size: 14px;
    color: #4a4a4a;
    margin-bottom: 28px;
  }
  .pwd-account {
    margin-bottom: 32px;
    :deep(.aui-input__inner) {
      height: 40px;
    }
    .account-error {
      color: #f5222d;
      font-size: 12px;
      padding-top: 4px;
    }
  } 
  .change-code-content {
    margin-bottom: 19px;
  }
  .pwd-code {
    color: #526ecc;
  } 
  .pwd-again-code {
    cursor: default;
  }
  .change-code {
    cursor: pointer;
    color: #526ecc;
  }
  .pwd-account-btn {
    @include btnBackground(#ea9fa3, 1);
  }
  .pwd-button {
    max-width: none;
    width: 100%;
    height: 40px;
    border-radius: 8px;
  }
}
@media screen and (max-width: 767px) {
  .pwd-code-content {
    .account-error {
      color: #f5222d;
      font-size: 12px;
      padding-top: 4px;
    }
    .pwd-button {
      max-width: none;
      width: 100%;
      height: 36px;
      font-size: 16px;
    }
    .pwd-account-btn {
      :deep(.aui-button.aui-button--primary) {
        border-color: #f66f6a;
        background: #f66f6a;
      }

      @include btnBackground(#f66f6a, 0.4);
    }
    .mobile-change-code {
      margin-top: 65px;
      width: 100%;
      text-align: center;
      color: #007dff;
      font-weight: 600;
    }
  }
}
.dialogBox {
	position: absolute;
  top: 50px;
	left: 50%;
	transform: translateX(-50%);
	background-color: #fff;
	display: block;
	z-index: 9999;
	width: 320px;
	min-height: 40px;
	box-shadow: 0px 2px 6px 0px #bfbfbf;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px 16px;
	border-radius: 2px;
	box-sizing: border-box;
}
.dialogBox span {
	font-size: 12px;
	font-family: Microsoft YaHei, Microsoft YaHei-Regular;
	font-weight: 400;
	color: #333333;
	line-height: 20px;
}
</style>