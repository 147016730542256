<template>
  <div class="error-content">
    <div class="hw-logo">
      <img src="../../assets/img/hw_logo.png" class="hw-logo-img" />
    </div>
    <div class="error-info">
      <div class="info-img">
        <img src="../../assets/img/403.png" />
      </div>
      <div class="info-tips">{{ $t('error403Content') }}</div>
    </div>
  </div>
</template>

<script>
import { killCookieFn } from '@/api'
import { getQueryVal, getCookie, getQueryValue } from '@/utils/utils';

export default {
  data() {
    return {
      language: getCookie('lang'),
    }
  },
  unmounted() {
    if (sessionStorage.getItem('cas-error-path')) {
      sessionStorage.removeItem('cas-error-path');
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.error-content {
  .hw-logo {
    width: 100%;
    box-sizing: border-box;
    padding: 0 5%;
    margin-top: 10px;
  }
  .hw-logo-img {
    display: block;
    width: 110px;
  }
  background: #fff;
  height: 100%;
  min-height: 830px;
  padding: 10px 0px 55px 0px;
}
.error-info {
  margin-top: 10%;
  .info-img {
    max-width: 524px;
    margin: 0 auto;
    margin-top: 68px;
    text-align: center;
  }
  .info-img img {
    width: 100%;
  }
  .info-title {
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: #333;
    font-weight: 700;
    margin: 42px auto 12px;
  }
  .info-tips {
    width: 100%;
    text-align: center;
    font-size: 18px;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 767px) {
  .error-content {
    .hw-logo {
      display: none;
    }
  }
  .error-info {
    margin-top: 35%;
    .info-tips {
      width: 80%;
      text-align: center;
      font-size: 14px;
      margin: 0 auto 24px;
    }
    .info-link {
      width: 80%;
      font-size: 14px;
      color: #333;
      text-align: center;
      margin: 0 auto;
    }
    .info-mobile-en > span {
      display: block;
    }
  }
}
</style>