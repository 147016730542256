<template>
  <div class="sso-vcode">
    <aui-input
      class="sso-vcode-input"
      v-bind="$attrs"
      :type="'text'"
    ></aui-input>
    <div
      class="sso-vcode-imgCode"
      @click="$emit('recode')"
    ><img
        :src="src"
        alt=""
      ></div>
  </div>
</template>
<script>
import { Input } from '@aurora/vue'
export default {
  name: 'ssoImageCode',
  inheritAttrs: false,
  components: {
    AuiInput: Input,
  },
  props: {
    src: String
  },
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
.sso-vcode {
  display: flex;
  .sso-vcode-input {
    flex: 1 1 100%;
    margin-right: 10px;
    display: block;
  }
  .sso-vcode-imgCode {
    min-width: 110px;
    flex: 0 0 auto;
    position: relative;
    text-align: center;
    background: #efefef;
    cursor: pointer;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 3px;
    }
  }
}
// #fa_IR,#ar_SA {
//   .sso-vcode {
//     .sso-vcode-input{
//       margin-right: 0px;
//       margin-left: 10px;
//     }
//   }
// }
</style>
