<template>
  <div class="nav-bar" @click="gotoPre">
    <icon-Chevron-Left class="nav-icon"></icon-Chevron-Left>
    <span>{{ title }}</span>
  </div>
</template>

<script>
import { IconChevronLeft } from '@aurora/vue-icon';
export default {
  name: 'PasswordHeader',
  components: {
    IconChevronLeft: IconChevronLeft(),
  },
  props: {
    title: String,
  },
  methods: {
    gotoPre() {
      this.$router.go(-1);
    },
  }
}
</script>

<style lang="scss" scoped>
.nav-bar {
  width: 100%;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  padding-left: 20px;
  border-bottom: 1px solid #d8d8d8;

  .nav-icon {
    margin-bottom: 3px;
    margin-right: 12px;
  }
}
</style>