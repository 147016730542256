<template>
  <div>
    <div class="visibulity" v-if="!isMobile"></div>
    <div class="content-main">
      <div class="info-title">{{ $t('checkUserInfo') }}</div>
      <div class="info-tips" v-if="!isSecond">
        {{ $t('checkUserWarnTips') }}
      </div>
      <div class="info-tips" v-if="isSecond">
        {{ $t('checkUserSecond') }}
      </div>
      <div class="send-info" v-if="showOptions.length === 1">
        {{ $t('sendCodeTitle') }}
        {{ sendAccountInfo }}
      </div>
      <div class="more-info" v-if="showOptions.length > 1"> 
        {{ moreAccountTips }} 
      </div>
      <aui-form
        ref="form"
        :model="formData"
        label-width="0"
        validate-type="text"
        :rules="rules"
        show-message
        @submit.prevent
      >
        <aui-form-item
          label=""
          v-if="showOptions.length > 1"
        >
          <aui-select v-model="sendAccountInfo" @change="changeAccountKey">
            <aui-option 
              v-for="item in showOptions" 
              :key="item.value" 
              :label="item.label" 
              :value="item.value">
            </aui-option>
          </aui-select>
        </aui-form-item>
        <aui-form-item
          label=""
          prop="code"
          class="itemStyle"
        >
          <aui-input
            v-model.trim="formData.code"
            :placeholder="inputPlaceholder"
            :disabled="inputDisabled"
            @keydown.enter="handlerClickEnter"
          >
            <template #suffix>
              <template v-if="phoneCode">
                <span @click="getSecondSendVerifyCodeFn('phone')" class="code-title" v-show="show">
                  {{ changeTip ? $t('resend') : $t('getCode') }}
                </span>
                <span 
                  class="code-title" 
                  v-show="!show" 
                  v-html="$t('againCode', {time: count})">
                </span>
              </template>
              <template v-else>
                <span @click="getSecondSendVerifyCodeFn('email')" class="code-title" v-show="showEmailTime">
                  {{ changeEmailTip ? $t('resend') : $t('getCode') }}
                </span>
                <span 
                  class="code-title" 
                  v-show="!showEmailTime" 
                  v-html="$t('againCode', {time: emailCount})">
                </span>
                </template>
            </template>
          </aui-input>
          <div class="code-error" v-if="errorState">{{ errorInfo }}</div>
          <div class="code-tip" v-if="changeTip || changeEmailTip">
            <IconCueL class="icon-cuel"></IconCueL>
            <span class="code-rtip">{{ codeRightTip }}</span>
          </div>
          <div class="dialogBox" v-if="showEmailCodeTip">
            <span>{{ $t('emailSendTo') + sendAccountInfo + $t('tenMinutes') }}</span>
          </div>
          <div class="dialogBox" v-if="showCodeTip">
            <span>{{ $t('smsSendTo') + sendAccountInfo + $t('tenMinutes') }}</span>
          </div>
        </aui-form-item>
        <div class="changeType">
          <aui-form-item
            label=""
            v-if="isShowChange"
          >
            <span class="change-code" @click="changeCode">
              {{ changePhoneOrEmail }}
            </span>
          </aui-form-item>
          <div v-else></div>
          <div class="vCode" @click="gotoHelp">
            {{ verificationCode }}
          </div>
        </div>
        <aui-form-item
          label=""
        >
          <div class="button-list">
            <aui-button class="button-li button-li-out" @click="handleClickCancel">{{ $t('loginOut') }}</aui-button>
            <aui-button class="button-li button-li-submit" 
              type="primary" :disabled="btnState" @click="handleClickCheck">{{ $t('ok') }}</aui-button>
          </div>
        </aui-form-item>
      </aui-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import $ from '@aurora/jquery';
import { Form, FormItem, Input, Button, Select, Option, Modal } from '@aurora/vue';
import { IconCueL } from '@aurora/vue-icon'
import { 
  isMobile,
  getCookie,
  getQueryVal,
  checkUserRange,
  getFpData,
  getQueryValue,
  getUrlStr
} from '@/utils/utils';
import { getAccountInfo, getSecondSendVerifyCode, checkSecondVerifyCode, setUniportalLink, getLogout } from '@/api';
import { stringifyQuery } from 'vue-router'
export default {
  name: 'Certification',
  components: {
    AuiForm: Form,
    AuiFormItem: FormItem,
    AuiInput: Input,
    AuiButton: Button,
    AuiSelect: Select,
    AuiOption: Option,
    IconCueL: IconCueL()
  },
  props: {
    serviceUrl: String,
    uidProxy: String,
    isSecond: Boolean,  
    fromUrl: String,
  },
  data() {
    const vm = this
    const codeBlur = (rule, value, callback) => {
      if (this.errorState) {
        this.errorState = false;
        this.errorInfo = '';
      }
      this.btnState = false;
      if (!vm.formData.code) {
        this.btnState = true;
      }
      return callback();
    }
    return {
      maskKey: '',
      isShowChange: false,
      btnState: true,
      isMobile: isMobile(),
      sendAccountInfo: '',
      moreAccountTips: '',
      changePhoneOrEmail: vm.$t('pwdCodeChangeEmail'),
      verificationCode: vm.$t('verificationCode'),
      codeRightTip: vm.$t('codeRightTip'),
      resend: vm.$t('resend'),
      changeTip: false,
      certificatState: 'SHOWSMS',
      formData: {
        code: '',
      }, 
      rules: {
        code: [{ validator: codeBlur, trigger: 'change' }],
      },
      showOptions: [],
      phoneOptions: [],
      emailOptions: [],
      inputPlaceholder: vm.$t('smsVerification'),
      inputDisabled: true,
      show: true,
      count: 0,
      errorState: false,
      errorInfo: '',
      phoneCode: true,
      showEmailTime: true,
      emailCount: 0,
      changeEmailTip: false,
      emailTimer: null,
      timer: null,
      showCodeTip: false,
      showEmailCodeTip: false,
      threetime: 3,
    };
  },
  watch: {
    uidProxy: {
      handler (value) {
        if (value.length) {
          this.getAccountInfoFn();
        }
      },
      immediate: true,
    }
  },
  unmounted() {
    const toCas = localStorage.getItem('toCasUrl');
    if (toCas) {
      localStorage.removeItem('toCasUrl');
    }
  },
  methods: {
    handlerClickEnter() {
      this.checkSecondVerifyCodeFn(this.formData.code);
    },
    // 帮助页面
    gotoHelp() {
      setUniportalLink('cas_uniportal_help').then(url => {
        window.open(url + '?caslogin=1&redirect=' + encodeURIComponent(location.href) + '&goBottom=true', '_blank');
      })
    },
    // 获取用户信息
    getAccountInfoFn() {
      const params = {
        uidProxy: this.uidProxy,
      };
      getAccountInfo(params).then(res => {
        if (res && res.accountInfo) {
          const { accountInfo } = res;
          const { emailList, phoneList } = accountInfo;

          this.phoneOptions = this.setOptions(phoneList, 'maskPhone');
          this.emailOptions = this.setOptions(emailList, 'maskEmail');

          const phoneOptionsList = this.phoneOptions;
          const emailOptionsList = this.emailOptions;

          // 电话存在
          if (phoneOptionsList.length) {
            this.setChangeCodeData('SHOWSMS', phoneOptionsList);
            this.isShowChange = emailOptionsList.length;
            this.phoneCode = true
          }

          // 邮箱存在电话不存在
          if (phoneOptionsList.length === 0 && emailOptionsList.length) {
            this.setChangeCodeData('SHOWEMAIL', emailOptionsList);
            this.phoneCode = false
          }
        }
      })
    },
    setOptions(dataList, keyVal) {
      if (dataList.length === 0) {
        return [];
      }
      let ListData = [];
      for (let i = 0; i < dataList.length; i++) {
        let obj = {
          id: 0,
          label: '',
          value: '',
          maskKey: '',
        }
        obj.id = i;
        obj.label = dataList[i][keyVal];
        obj.value = dataList[i][keyVal];
        obj.maskKey = dataList[i].maskKey;
        ListData.push(obj);
      }
      return ListData;
    },
    changeAccountKey(value) {
      const data = this.showOptions.find(el => el.value === value);
      this.maskKey = data.maskKey;
    },
    emailDialogTip () {
      let self = this
      let threetimer = setInterval(function () {
        if (self.threetime == 1) {
          clearInterval(threetimer)
          self.showEmailCodeTip = false
          self.threetime = 3
        } else {
          self.threetime--
        }
      }, 1000)
    },
    phoneDialogTip () {
      let self = this
      let threetimer = setInterval(function () {
        if (self.threetime == 1) {
          clearInterval(threetimer)
          self.showCodeTip = false
          self.threetime = 3
        } else {
          self.threetime--
        }
      }, 1000)
    },
    // 发送验证码
    getSecondSendVerifyCodeFn(transferType) {
      if (this.errorState) {
        this.errorState = false;
        this.errorInfo = '';
      }
      const type = this.certificatState === 'SHOWSMS' ? 'sms' : 'email';
      const params = {
        maskKey: this.maskKey,
        account: this.uidProxy,
        type,
        language: getCookie('lang'),
      };
      getSecondSendVerifyCode(params).then(res => {
        if (res.mfaMsg) {
          this.inputDisabled = false;
          if (transferType == 'phone') {
            this.changeTip = true;
            this.showCodeTip = true
            this.phoneDialogTip()
          } else if (transferType == 'email') {
            this.changeEmailTip = true;
            this.showEmailCodeTip = true
            this.emailDialogTip()
          }
          this.getCodeNum(transferType);
          return;
        }

        if (!res.errorCode) return;
        this.errorState = true;
        this.errorInfo = this.$t(res.errorCode);
      })
    },
    handleClickCheck() {
      this.checkSecondVerifyCodeFn(this.formData.code);
    },
    checkSecondVerifyCodeFn(code) {
      const type = this.certificatState === 'SHOWSMS' ? 'sms' : 'email';
      let fp = getFpData();
      const params = {
        maskKey: this.maskKey,
        account: this.uidProxy,
        type,
        code,
        fingerPrint: fp,
      };
      checkSecondVerifyCode(params).then(res => {
        if (res.mfaMsg) {
          // 成功
          if (res.callbackUrl) {
            if (res.accountInfo && res.accountInfo.pwdRemainDays) {
              this.pwdWillExpired(res.accountInfo.pwdRemainDays, res.callbackUrl);
              return;
            } 
            const storageName = this.fromUrl === 'w3Account' ? 'w3' : 'uniportal';
            window.localStorage && window.localStorage.setItem('hisPage', storageName);
            checkUserRange(res.callbackUrl, this, this.fromUrl);
          }
          return;
        }

        if (!res.errorCode) return;

        if (res.errorCode === 'CAS-08-00007') {
          this.errorState = true;
          this.errorInfo = this.$t('verifyCodeError');
        }
      })
    },
    pwdWillExpired(pwdRemainDays, callbackUrl) {
      const query = {
        pwdRemainDays,
        callbackUrl: encodeURIComponent(callbackUrl),
        service: this.serviceUrl,
        tenantId: getQueryVal('tenantId')
      }
      const queryString = getUrlStr(query);
      this.$router.push(`/expiringPassword/index?${queryString}`)
    },
    getCodeNum(transferType) {
      const TIME_COUNT = 60;
      if (transferType == 'phone') {
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000)
        }
      } else if (transferType == 'email') {
        if (!this.emailTimer) {
          this.emailCount = TIME_COUNT;
          this.showEmailTime = false;
          this.emailTimer = setInterval(() => {
            if (this.emailCount > 0 && this.emailCount <= TIME_COUNT) {
              this.emailCount--;
            } else {
              this.showEmailTime = true;
              clearInterval(this.emailTimer);
              this.emailTimer = null;
            }
          }, 1000)
        }
      }
    },
    logoutFn() {
      if (!isMobile()) {
        const path = '/' + this.fromUrl;
        const query = { service: getQueryValue('service'), tenantId: getQueryVal('tenantId') };
        const queryString = getUrlStr(query);
        this.$router.push(`${path}?${queryString}`)
      } else {
        this.$emit('handleCloseCertifica', false);
      }
    },
    // 切换校验方式
    changeCode() {
      if (this.certificatState === 'SHOWSMS') {
        this.phoneCode = false
        this.setChangeCodeData('SHOWEMAIL', this.emailOptions);
      } else if (this.certificatState === 'SHOWEMAIL') {
        this.phoneCode = true
        this.setChangeCodeData('SHOWSMS', this.phoneOptions);
      }
    },
    setChangeCodeData(stateTitle, optionsList) {
      this.certificatState = stateTitle;
      this.showOptions = optionsList;
      this.sendAccountInfo = optionsList[0].value;
      this.maskKey = optionsList[0].maskKey;
      this.inputPlaceholder = stateTitle === 'SHOWSMS' ? this.$t('smsVerification') : this.$t('emailVerification');
      this.moreAccountTips = stateTitle === 'SHOWSMS' ? this.$t('morePhoneTips') : this.$t('moreEmailTips');
      this.changePhoneOrEmail = 
        stateTitle === 'SHOWSMS' ? this.$t('pwdCodeChangeEmail') : this.$t('pwdCodeChangePhone');
    },
    handleClickCancel() {
      const data = {
        service: getQueryVal('service'),
        redirect: getQueryVal('redirect')
      }
      getLogout(data).then((res) => {
        if (res) {
          this.getLogoutInfo(res)
        }
      })
    },
    getLogoutInfo(data) {
      const vm = this
      let protocol = location.protocol
      const baseUrl = process.env.VUE_APP_BASE_API
      axios.post(baseUrl + '/get-logout-info?logoutKey=' + data).then(function (res) {
        const urlList = res.data.logoutUrlList
        const errorCode = res.data.errorCode
        if (
          errorCode == 'CAS-03-00006' ||
          errorCode == 'CAS-03-00007' ||
          errorCode == 'CAS-01-00004' ||
          errorCode == 'CAS-00-00001'
        ) {
          vm.errorMsg = this.$t(errorCode);
          vm.errorFlag = true;
          return;
        } 

        if (!urlList) {
          vm.logoutFn();
          return
        }

        urlList &&
          urlList.forEach((url) => {
            let repUrl = ''
            if (url.indexOf(protocol) == -1) {
              if (url.indexOf('http:') != -1) {
                repUrl = url.replace('http:', protocol)
              } else {
                repUrl = url.replace('https:', protocol)
              }
            } else {
              repUrl = url
            }
            $.ajax({
              type: 'get',
              url: repUrl,
              dataType: 'jsonp',
              async: false,
              jsonpCallback: 'showData',
              success: function (data) {
                return 'success'
              }
            })
          })
        vm.logoutFn();
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@mixin btnBackground($color, $opacity) {
  :deep(.aui-button.aui-button--primary.is-disabled),
  :deep(.aui-button.aui-button--primary.is-disabled:active),
  :deep(.aui-button.aui-button--primary.is-disabled:focus),
  :deep(.aui-button.aui-button--primary.is-disabled:hover) {
    border-color: $color;
    background-color: $color;
    opacity: $opacity;
  }
}

.visibulity {
  height: 80px;
}
.content-main {
  width: 558px;
  margin: 0 auto;
  height: auto;
  color: #4a4a4a;
  background: #fff;
  padding: 64px 63px 101px 63px;
  box-sizing: border-box;

  :deep(.aui-input.is-disabled .aui-input__inner) {
    background: #f6f6f6;
  }
  :deep(.aui-button.aui-button--primary) {
    background: #ee5b55;
    border-color: #ee5b55;
  }
  .info-title {
    width: 100%;
    text-align: center;
    font-size: 24px;
    margin-bottom: 32px;
  }
  .info-tips {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .send-info {
    font-size: 12px;
    color: #333;
    margin-bottom: 20px;
  }
  .itemStyle {
    margin-bottom: 16px;
  }
  .more-info {
    font-size: 14px;
    color: #333;
    margin-bottom: 20px;
  }
  .code-title {
    color: #007dff;
  }
  .code-error {
    color: #f5222d;
    font-size: 12px;
    padding-top: 4px;
  }
  .code-tip {
    color: #999;
    font-size: 12px;
    padding-top: 4px;
    display: flex;
    align-items: center;
  }
  .icon-cuel {
    fill: #007dff;
    font-size: 12px;
  }
  .code-rtip {
    padding-left: 5px;
  }
  .changeType {
    display: flex;
    justify-content: space-between;
  }
  .vCode {
    font-size: 14px;
    color: #007dff;
    cursor: pointer;
    margin-bottom: 24px;
  }
  .change-code {
    cursor: pointer;
    color: #007dff;
  }
  .button-list {
    display: flex;
    justify-content: space-between;
  }
  .button-li {
    max-width: none;
    width: 200px;
    height: 48px;
    font-size: 16px;
    border-radius: 4px;
  }
  .button-li-submit {
    color: #fff;
    @include btnBackground(#ea9fa3, 1);
  }
  :deep(.button-li-out.aui-button:focus), 
  :deep(.button-li-out.aui-button:hover) {
    border-color: #d9d9d9;
  }
  :deep(.button-li-out.aui-button.is-disabled),
  :deep(.button-li-out.aui-button.is-disabled:focus),
  :deep(.button-li-out.aui-button.is-disabled:hover) {
    color: #000;
    border-color: #d9d9d9;
    background-color: #fff;
  }
}

@media screen and (max-width: 767px) {
  .main {
    background: #fff; 
  }
  .content-main {
    width: 100%;
    margin-top: 35px;
    height: auto;
    background: #fff;
    padding: 0 20px;
    box-sizing: border-box;
  }
}
.dialogBox {
	position: absolute;
  top: 60px;
	left: 50%;
	transform: translateX(-50%);
	background-color: #fff;
	display: block;
	z-index: 9999;
	width: 320px;
	min-height: 40px;
	box-shadow: 0px 2px 6px 0px #bfbfbf;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px 16px;
	border-radius: 2px;
	box-sizing: border-box;
}
.dialogBox span {
	font-size: 12px;
	font-family: Microsoft YaHei, Microsoft YaHei-Regular;
	font-weight: 400;
	color: #333333;
	line-height: 20px;
}
</style>