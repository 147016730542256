<template>
  <div class="resetRedirect">
    <div class="mainBox">
      <div class="title">{{$t('rpw')}}</div>
      <div class="cardBox">
        <div class="card_l">
          <p>{{$t('uniportalAccount')}}</p>
          <span>
            {{$t('uniportalGives')}}
          </span>
          <div class="btn">
            <aui-button
              type="text"
              @click="uniportalPw"
            >{{$t('UniportalA')}}</aui-button>
          </div>
        </div>
        <div class="line"></div>
        <div class="card_r">
          <p>{{$t('hwAccount')}}</p>
          <span>
            {{$t('hwGives')}}
          </span>
          <div class="btn">
            <aui-button
              type="text"
              @click="hwidPw"
            >{{$t('hwA')}}</aui-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from '@aurora/vue'
import { hwidFwUrl } from '@/api'
import { getQueryValue, getQueryVal, getDecodeUrlParams, getUrlStr } from '@/utils/utils'
export default {
  data() {
    return {
      forgotPwdUrl: ''
    }
  },
  components: {
    AuiButton: Button
  },
  created() {
    this.getTargetUtl()
  },
  methods: {
    getTargetUtl() {
      const _vm = this;
      const params = {
        lang: this.$i18n.locale,
        mobile: this.isMobile(),
        loginUrl: getQueryVal('loginUrl')
      }
      hwidFwUrl(params).then(res => {
        if (res.forgotPwdUrl) {
          _vm.forgotPwdUrl = res.forgotPwdUrl
        }
      })
    },
    hwidPw() {
      location.href = this.forgotPwdUrl
    },
    uniportalPw() {
      let query = { type: 2, service: getQueryValue('service'), tenantId: getQueryVal('tenantId'), urlType: 0 };
      const queryString = getUrlStr(query);
      this.$router.push(`/resetPwd?${queryString}`)
    },
    isMobile: function() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) || window.innerWidth <= 500
    },
  }
}
</script>
<style lang="scss" scoped>
.aui-button {
  max-width: 228px;
  padding: 0;
}
.resetRedirect {
  display: flex;
  justify-content: center;
  .mainBox {
    width: 60%;
    min-width: 800px;
    margin-top: 40px;
    background: #fff;
    .title {
      font-size: 28px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      text-align: center;
      margin: 50px 0;
    }
    .cardBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 120px;
      .card_r,
      .card_l {
        padding: 10px 100px;
        flex: 1 1 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .btn {
          margin-top: 50px;
        }
        p {
          font-size: 18px;
          font-family: Microsoft YaHei, Microsoft YaHei-Bold;
          font-weight: 700;
        }
        span {
          font-size: 12px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
        }
      }
      .line {
        border-left: 1px solid #ccc;
        margin: 45px 0;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .resetRedirect .mainBox {
    width: auto;
    min-width: auto;
    padding: 20px;
    .title {
      margin: 25px 0 20px 0;
      font-size: 20px;
      text-align: left;
    }
    .cardBox {
      display: inline-block;
      margin-bottom: auto;
      .card_r,
      .card_l {
        padding: 10px;
        width: auto;
        background: #f6f6f6;
        border-radius: 8px;
        .btn {
          margin-top: 20px;
        }
        p {
          font-size: 16px;
        }
        span {
          font-size: 14px;
        }
      }
    }
  }
}
</style>