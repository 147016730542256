<template>
  <div>
    <div class="visibulity" v-if="!isMobile"></div>
    <password-header 
      class="passwordHeader" 
      :title="setPwdTitle" 
      v-if="isMobile && pageState !== 4">
    </password-header>
    <div class="pwd-main">
      <div class="pwd-title" v-if="!isMobile && pageState !== 4">
        {{ setPwdTitle }}
      </div>
      <!-- 校验账号 -->
      <pwd-account 
        v-if="pageState === 1" 
        :isMobile="isMobile" 
        :uid="uid"
        :serviceUrl="serviceUrl"
        :captchaObj="captchaObj"
        @changePage="changePage">
      </pwd-account>

      <!-- 多账号选择 -->
      <multi-account
        v-if="pageState === 11"
        :isMobile="isMobile"
        :uidType="uidType"
        :multiAccountOptions="multiAccountOptions"
        @changePage="changePage"></multi-account>

      <!-- 获取验证码 -->
      <pwd-captcha-code
        v-if="pageState === 2" 
        :isMobile="isMobile" 
        :checkCode="checkCode"
        @changePage="changePage">
      </pwd-captcha-code>

      <!-- 重新设置密码 -->
      <set-password
        v-if="pageState === 3" 
        :isMobile="isMobile" 
        :uid="checkCode.uid"
        :type="type"
        @changePage="changePage">
      </set-password>

      <!-- 密码重置成功 -->
      <pwd-success
        v-if="pageState === 4" 
        :serviceUrl="serviceUrl"
        :domainObj="domainObj"
        :hwState="hwState"
        @changePage="changePage">
      </pwd-success>

      <div 
        class="callback-login" 
        @click="callbackLogin" 
        v-if="!isMobile && pageState === 1">
        {{ $t('backLoginTips') }}
      </div>
    </div>
  </div>
</template>

<script>
import PasswordHeader from '../layout/PasswordHeader';
import PwdAccount from './PwdAccount';
import PwdCaptchaCode from './PwdCaptchaCode';
import SetPassword from './SetPassword';
import PwdSuccess from './PwdSuccess';
import MultiAccount from './MultiAccount';
import { isMobile, getQueryVal, callbackLoginMethod, getQueryValue, getUrlStr } from '@/utils/utils';
import { getCaptchaParams } from '@/api';
export default {
  name: 'ResetPwd',
  components: {
    PasswordHeader,
    PwdAccount,
    PwdCaptchaCode,
    SetPassword,
    PwdSuccess,
    MultiAccount,
  },
  data() {
    return {
      pageState: 1,
      uid: '',
      uidType: '',
      checkCode: {
        uid: '',
        phoneNumber: '',
        email: '',
        callCountryCode: '',
      },
      type: '',  
      isMobile: isMobile(),
      serviceUrl: '',
      domainObj: {
        uid: '',
        newPassword: '',
      },
      hwState: false,
      captchaObj: {},
    };
  },
  computed: {
    setPwdTitle() {
      return Number(this.$route.query.type) === 1
        ? this.$t('changePassword') 
        : this.$t('restPassword');
    },
  },
  created() {
    if (Number(this.$route.query.type) === 2) {
      this.$route.meta.title = this.$t('resetPassword');
    }
  },
  unmounted() {
    const callScript = document.querySelector("script[data-callType='captchaJs']");
    if (callScript) {
      document.head.removeChild(callScript);
    }
  },
  mounted() {
    this.type = Number(this.$route.query.type);
    if (this.$route.query.uid) {
      this.uid = this.$route.query.uid;
    }
    if (this.$route.query.service) {
      this.serviceUrl = getQueryValue('service');
    }
    this.getCaptchaParamsFn()
  },
  methods: {
    callbackLogin() {
      const path = callbackLoginMethod(getQueryVal('urlType'))
      if(path == '/account'){
        localStorage.setItem('hisPage', 'hw')
        location.reload()
      }else {
        const query = { service: this.serviceUrl, tenantId: getQueryVal('tenantId') };
        const queryString = getUrlStr(query);
        this.$router.push(`${path}?${queryString}`)
      }
    },
    changePage(data) {
      this.pageState = data.page;
      this.uidType = data.uidType;
      this.multiAccountOptions = data.multiAccountOptions;
      if (data.page === 2) {
        this.domainObj.uid = data.uid;
        this.checkCode.uid = data.uid;
        this.checkCode.phoneNumber = data.phoneNumber;
        this.checkCode.email = data.email;
        this.checkCode.callCountryCode = data.callCountryCode;
        this.checkCode.maskUid = data.maskUid;
      }
      if (data.page === 4 && data.state) {
        this.hwState = data.state;
        this.domainObj.newPassword = data.newPassword;
      }
    },
    getCaptchaParamsFn() {
      getCaptchaParams().then(res => {
        if (res) {
          this.captchaObj = res;
          this.addScript(res.captchaJs);
        }
      })
    },
    addScript(url) { 
      var script = document.createElement('script'); 
      script.setAttribute('type','text/javascript'); 
      script.setAttribute('data-callType','captchaJs'); 
      script.setAttribute('src', url); 
      document.getElementsByTagName('head')[0].appendChild(script);
    },
  },
}
</script>

<style lang="scss" scoped>
.visibulity {
  height: 80px;
}
.pwd-main {
  width: 558px;
  margin: 0 auto;
  height: auto;
  color: #4a4a4a;
  background: #fff;
  padding: 64px 63px 101px 63px;
  box-sizing: border-box;

  .pwd-title {
    width: 100%;
    height: 42px;
    font-size: 32px;
    font-family: PingFangSC, PingFangSC-Semibold;
    text-align: center;
    color: #191919;
    line-height: 42px;
    margin-bottom: 20px;
  }

  .callback-login {
    cursor: pointer;
    font-size: 14px;
    color: #4a4a4a;
  }
}

@media screen and (max-width: 767px) {
  .main {
    background: #fff;
  }
  .pwd-main {
    width: 100%;
    margin-top: 35px;
    height: auto;
    background: #fff;
    padding: 0 20px;
    box-sizing: border-box;
  }
}
</style>