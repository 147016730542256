<template>
  <div class="error-content">
    <div class="hw-logo">
      <img src="../../assets/img/hw_logo.png" class="hw-logo-img" />
    </div>
    <div class="error-info">
      <div class="info-img">
        <img src="../../assets/img/error_icon.png" />
      </div>
      <div class="info-title">{{ $t('restrictedAccess') }}</div>
      <div class="info-tips">{{ $t('permissionError') }}</div>
      <div class="info-link" :class="{'info-mobile-en': language === 'en_US'}">
        <span>{{ $t('changeAccountTips') }}</span>
        <span class="info-link-account" @click="handleClickAccount">
          {{ $t('changeAccount') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { killCookieFn } from '@/api'
import { getQueryVal, getCookie, getQueryValue } from '@/utils/utils';

export default {
  data() {
    return {
      language: getCookie('lang'),
    }
  },
  unmounted() {
    if (sessionStorage.getItem('cas-error-path')) {
      sessionStorage.removeItem('cas-error-path');
    }
  },
  methods: {
    handleClickAccount() {
      killCookieFn().then(() => {
        if (sessionStorage.getItem('cas-error-path')) {
          const queryString = `service=${getQueryValue('service')}&tenantId=${getQueryVal('tenantId')}`;
          this.$router.push(`${sessionStorage.getItem('cas-error-path')}?${queryString}`)
          sessionStorage.removeItem('cas-error-path');
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.error-content {
  .hw-logo {
    width: 100%;
    box-sizing: border-box;
    padding: 0 5%;
    margin-top: 10px;
  }
  .hw-logo-img {
    display: block;
    width: 110px;
  }
}
.error-info {
  margin-top: 10%;
  .info-img {
    width: 232px;
    margin: 0 auto;
  }
  .info-img img {
    width: 100%;
  }
  .info-title {
    width: 100%;
    text-align: center;
    font-size: 24px;
    color: #333;
    font-weight: 700;
    margin: 42px auto 12px;
  }
  .info-tips {
    width: 100%;
    text-align: center;
    font-size: 18px;
    margin-bottom: 24px;
  }
  .info-link {
    width: 100%;
    font-size: 14px;
    color: #333;
    text-align: center;
  }
  .info-link-account {
    cursor: pointer;
    color: #1890ff;
    margin-left: 5px;
  }
}

@media screen and (max-width: 767px) {
  .error-content {
    .hw-logo {
      display: none;
    }
  }
  .error-info {
    margin-top: 35%;
    .info-tips {
      width: 80%;
      text-align: center;
      font-size: 14px;
      margin: 0 auto 24px;
    }
    .info-link {
      width: 80%;
      font-size: 14px;
      color: #333;
      text-align: center;
      margin: 0 auto;
    }
    .info-mobile-en > span {
      display: block;
    }
  }
}
</style>