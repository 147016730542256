 <template>
  <div class="pwd-setpasswod">
    <div class="account-content">
      <aui-form
        ref="form"
        :model="formData"
        label-width="0"
        validate-type="text"
        :rules="rules"
        show-message
      >
        <aui-form-item
          label=""
          v-if="Number($route.query.type) === 1"
          prop="oldPassword"
        >
          <ssoPwd
            v-model.trim="formData.oldPassword"
            :placeholder="$t('oldPassword')"
          ></ssoPwd>
          <div class="pwd-error" v-if="erroroldPassword">{{ $t('oldPasswordError') }}</div>
        </aui-form-item>

        <aui-form-item
          label=""
          class="set-password-new"
          prop="newPassword"
        >
          <ssoPwd
            v-model.trim="formData.newPassword"
            :placeholder="$t('newPassword')"
          ></ssoPwd>
          <aui-tooltip class="item" effect="dark" placement="right">
            <template #content>
              <ul style="line-height: 20px;">
                <li v-for="(item, index) in passwordRules" :key="index">{{item}}</li>
              </ul>
            </template>
            <icon-cue-l class="aui-svg-size icon-cue-l set-password-tips" v-if="!isMobile"></icon-cue-l>
          </aui-tooltip>
          <div class="pwd-error" v-if="errornewPassword">{{ errornewPasswordTips }}</div>
        </aui-form-item>

        <aui-form-item
          label=""
          prop="confirmNewPassword"
        >
          <ssoPwd
            v-model.trim="formData.confirmNewPassword"
            :placeholder="$t('confirmNewPassword')"
          ></ssoPwd>
        </aui-form-item>

        <aui-form-item class="pwd-account-btn">
          <aui-button
            type="primary"
            class="pwd-button"
            @click="next"
            :disabled="btnState"
          >{{$t('next')}}</aui-button>
        </aui-form-item>
      </aui-form>
    </div>
  </div>
</template>

<script>
import { Form, FormItem, Button, Modal, Tooltip } from '@aurora/vue';
import { IconCueL } from '@aurora/vue-icon';
import ssoPwd from '@/components/form/ssoPwd';
import { modifyPassword, resetPassword } from '@/api';

export default {
  name: 'SetPassword',
  components: {
    AuiForm: Form,
    AuiFormItem: FormItem,
    AuiButton: Button,
    AuiTooltip: Tooltip,
    IconCueL: IconCueL(),
    ssoPwd,
  },
  props: {
    isMobile: Boolean,
    uid: String,
    type: Number,
  },
  data() {
    const vm = this;
    const passwordreg = /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9])/;
    const oldPwdBlur = (rule, value, callback) => {
      if (this.erroroldPassword) {
        this.erroroldPassword = false;
      }
      if (value === '') {
        callback(new Error(vm.$t('pwdIsEmpty')));
      }
      return callback();
    }
    
    const checkValidator = (value, pwd, callback) => {
      if (value.length < 8 || value.length > 60) {
        callback(new Error(vm.$t('pwdTip')))
      } else if (value.indexOf(this.uid) !== -1) {
        callback(new Error(vm.$t('passwordTip2')))
      } else if (!passwordreg.test(value)) {
        callback(new Error(vm.$t('passwordTip3')))
      } else if (pwd !== '' && pwd !== value) {
        callback(new Error(vm.$t('cpmbtsanp')));
      } else if (value.indexOf(' ') !== -1) {
        callback(new Error(vm.$t('npcsoewas')))
      }
      return callback();
    }

    const newPwdBlur = (rule, value, callback) => {
      const confirmNewPasswordVal = this.formData.confirmNewPassword;
      if (this.errornewPassword) {
        this.errornewPassword = false;
      }

      checkValidator(value, confirmNewPasswordVal, callback);
    }
    const nowPwdBlur = (rule, value, callback) => {
      checkValidator(value, this.formData.newPassword, callback);
    }
    return {
      btnState: true,
      erroroldPassword: false,
      errornewPassword: false,
      errornewPasswordTips: '',
      passwordRules: [
        vm.$t('passwordRules1'),
        vm.$t('passwordRules2'),
        vm.$t('passwordRules3'),
        vm.$t('passwordRules4'),
        vm.$t('passwordRules5'),
      ],
      formData: {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      },
      rules: {
        oldPassword: [{ validator: oldPwdBlur, trigger: 'blur' }],
        newPassword: [{ validator: newPwdBlur, trigger: 'blur' }],
        confirmNewPassword: [{ validator: nowPwdBlur, trigger: 'blur' }],
      },
    };
  },
  computed: {
    passwordVal() {
      const { oldPassword, newPassword, confirmNewPassword } = this.formData;
      return { oldPassword, newPassword, confirmNewPassword };
    }
  },
  watch: {
    passwordVal(val) {
      if (this.type === 1) {
        if (val.oldPassword && val.newPassword && val.confirmNewPassword) {
          this.btnState = false;
          return;
        }
        this.btnState = true;
      } else if (this.type === 2) {
        if (val.newPassword && val.confirmNewPassword) {
          this.btnState = false;
          return;
        }
        this.btnState = true;
      }
    }
  },
  methods: {
    next() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            uid: this.uid,
            newPassword: this.formData.newPassword,
            confirmNewPassword: this.formData.confirmNewPassword,
            pwdStrength: this.formData.newPassword.length >= 14 ? 1 : 0,
            appUrl: '',
          };

          if (this.type === 1) {
            this.modifyPasswordFn(params);
          } else if (this.type === 2) {
            this.resetPasswordFn(params);
          }
        }
      })
    },
    // 修改密码
    modifyPasswordFn(params) {
      params.oldPassword = this.formData.oldPassword;
      modifyPassword(params).then(res => {
        if (['true', 'false'].includes(String(res))) {
          this.$emit('changePage', { page: 4, state: res, newPassword: params.newPassword });
        }

        if (!res.errorCode) return;
        if (res.errorCode === 'CAS-02-00011') {
          this.erroroldPassword = true;
        } else if (['CAS-PWd-0001', 'CAS-02-00031'].includes(res.errorCode)) {
          this.errornewPassword = true;
          this.errornewPasswordTips = this.$t('newPasswordError');
        } else if (res.errorCode === 'IDC-SMS-00029') {
          this.longTime();
        } else if (res.errorCode === 'CAS-02-00030') {
          this.errornewPassword = true;
          this.errornewPasswordTips = this.$t('newPwdErrorTips');
        } else {
          this.errornewPassword = true;
          this.errornewPasswordTips = this.$t(res.errorCode);
        }
      })
    },
    // 重置密码
    resetPasswordFn(params) {
      params.oldPassword = '';
      resetPassword(params).then(res => {
        if (['true', 'false'].includes(String(res))) {
          this.$emit('changePage', { page: 4, state: res, newPassword: params.newPassword });
        }

        if (!res.errorCode) return;
        if (['CAS-PWd-0001', 'CAS-02-00031'].includes(res.errorCode)) {
          this.errornewPassword = true;
          this.errornewPasswordTips = this.$t('newPasswordError');
        } else if (res.errorCode === 'IDC-SMS-00029') {
          this.longTime();
        } else if (res.errorCode === 'CAS-02-00030') {
          this.errornewPassword = true;
          this.errornewPasswordTips = this.$t('newPwdErrorTips');
        } else {
          this.errornewPassword = true;
          this.errornewPasswordTips = this.$t(res.errorCode);
        }
      })
    },
    longTime() {
      Modal.message({
        message: this.$t('longTime'),
        status: 'error',
        duration: '1500'
      });
      setTimeout(() => {
        this.$emit('changePage', { page: 1 });
      }, 1700)
    },
  },
}
</script>

<style lang="scss" scoped>
@mixin btnBackground($color, $opacity) {
  :deep(.aui-button.aui-button--primary.is-disabled), 
  :deep(.aui-button.aui-button--primary.is-disabled:active), 
  :deep(.aui-button.aui-button--primary.is-disabled:focus), 
  :deep(.aui-button.aui-button--primary.is-disabled:hover) {
    border-color: $color;
    background-color: $color;
    opacity: $opacity;
  }
}

.pwd-setpasswod {
  .account-content {
    :deep(.aui-form-item) {
      margin-bottom: 20px;
    }
    :deep(.aui-input__inner) {
      height: 40px;
    }
    .pwd-error {
      color: #f5222d;
      font-size: 12px;
      padding-top: 4px;
    }
  }
  .pwd-button {
    max-width: none;
    width: 100%;
    margin-top: 12px;
    height: 40px;
    border-radius: 8px;
  }
  .set-password-new {
    position: relative;
  }
  .set-password-tips {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: -36px;
    fill: #4a4a4a;
    font-size: 24px;
    opacity: 0.6;
  }
  .pwd-account-btn {
    @include btnBackground(#ea9fa3, 1);
  }
}
@media screen and (max-width: 767px) {
  .pwd-setpasswod {
    .pwd-button {
      max-width: none;
      width: 100%;
      height: 36px;
      font-size: 16px;
    }
    .pwd-account-btn {
      :deep(.aui-button.aui-button--primary) {
        border-color: #f66f6a;
        background: #f66f6a;
      }
      
      @include btnBackground(#f66f6a, 0.4);
    }
  }
}
</style>