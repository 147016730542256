<template>
  <div class="welcome-box">
    <div class="visibulity"></div>
    <div class="welcome-img" v-if="!errorFlag">{{ $t('loggingOut') }}</div>
    <div class="welcome-img" v-if="errorFlag">{{ errorMsg }}</div>
  </div>
</template>
<script>
import axios from 'axios'
import { getQueryVal } from '@/utils/utils'
import { getLogout } from '@/api'
import $ from '@aurora/jquery'
export default {
  name: 'logoutLoading',
  data() {
    return {
      errorMsg: '',
      errorFlag: false
    }
  },
  created() {
    this.logout()
  },
  methods: {
    logout() {
      const data = {
        service: getQueryVal('service'),
        redirect: getQueryVal('redirect')
      }
      getLogout(data).then((res) => {
        if (res) {
          this.getLogoutInfo(res)
        }
      })
    },
    getLogoutInfo(data) {
      const vm = this
      let protocol = location.protocol
      const baseUrl = process.env.VUE_APP_BASE_API
      axios.post(baseUrl + '/get-logout-info?logoutKey=' + data).then(function (res) {
        const urlList = res.data.logoutUrlList
        const errorCode = res.data.errorCode
        if (
          errorCode == 'CAS-03-00006' ||
          errorCode == 'CAS-03-00007' ||
          errorCode == 'CAS-01-00004' ||
          errorCode == 'CAS-00-00001'
        ) {
          vm.errorMsg = this.$t(errorCode)
          vm.errorFlag = true
        } else {
          urlList &&
            urlList.forEach((url) => {
              let repUrl = ''
              if (url.indexOf(protocol) == -1) {
                if (url.indexOf('http:') != -1) {
                  repUrl = url.replace('http:', protocol)
                } else {
                  repUrl = url.replace('https:', protocol)
                }
              } else {
                repUrl = url
              }
              $.ajax({
                type: 'get',
                url: repUrl,
                dataType: 'jsonp',
                async: false,
                jsonpCallback: 'showData',
                success: function (data) {
                  return 'success'
                }
              })
            })
          if (res.data.redirect) {
            location.href = res.data.redirect
          }
        }
      })
    },
    showData(data) {
      return data
    }
  }
}
</script>
<style lang="scss" scoped>
.visibulity {
  height: 40px;
}
.welcome-img {
  width: 558px;
  height: 513px;
  background: #ffffff;
  margin: 0 auto;
  text-align: center;
  padding: 50px 0;
  line-height: 450px;
}
</style>
