<template>
  <div class="pwd-account-content">
    <div class="pwd-tips">{{ setPwdTips }}</div>
    <div class="account-content">
      <aui-form
        ref="form"
        :model="formData"
        label-width="0"
        validate-type="text"
        :rules="rules"
        show-message
        @submit.prevent
      >
        <aui-form-item
          label=""
          prop="uid"
          class="pwd-account"
        >
          <aui-input
            v-model.trim="formData.uid"
            :placeholder="$t('setAccountPlaceholder')"
          ></aui-input>
          <div class="account-error" v-if="errorState">{{ $t('accountNotExist') }}</div>
        </aui-form-item>

        <aui-form-item class="pwd-account-btn">
          <aui-button
            type="primary"
            class="pwd-button"
            @click="next"
            :disabled="btnState"
          >{{$t('next')}}</aui-button>
        </aui-form-item>

        <aui-form-item v-if="isMobile">
          <aui-button
            class="pwd-button pwd-mobile-callback"
            @click="callbackLogin"
          >{{$t('backLogin')}}</aui-button>
        </aui-form-item>
      </aui-form>
    </div>
    <Captcha 
      ref="captcha" 
      :lang="lang" 
      :captchaObj="captchaObj"
      @nextPwd="verifyUserCheck" 
      @changeBtnState="changeBtnState" 
    />
  </div>
</template>

<script>
import { Form, FormItem, Input, Button } from '@aurora/vue';
import { w3Check } from '@/api';
import Captcha from './Captcha.vue';
import { getCookie, getQueryVal, callbackLoginMethod, getQueryValue, getUrlStr } from '@/utils/utils';

export default {
  name: 'PwdAccount',
  components: {
    AuiForm: Form,
    AuiFormItem: FormItem,
    AuiInput: Input,
    AuiButton: Button,
    Captcha,
  },
  props: {
    isMobile: Boolean,
    uid: String,
    serviceUrl: String,
    captchaObj: Object,
  },
  data() {
    const vm = this;
    const accountBlur = (rule, value, callback) => {
      this.btnState = false;
      this.errorState = false;
      if (!vm.formData.uid) {
        this.btnState = true;
        callback(new Error(vm.$t('accountIsEmpty')));
      } else if (vm.formData.uid.length > 100) {
        this.btnState = true;
        callback(new Error(vm.$t('accountTip')));
      } else {
        this.btnState = false;
        callback();
      }
    }
    return {
      btnState: true,
      errorState: false,
      formData: {
        uid: '',
        maskUid: '',
      },
      rules: {
        uid: [{ validator: accountBlur, trigger: ['blur', 'change'] }],
      },
      lang: getCookie('lang').replace('_', '-'),
      validateVal: {},
      multiAccount: false,
      multiAccountOptions: [],
    };
  },
  computed: {
    setPwdTips() {
      return Number(this.$route.query.type) === 1
        ? this.$t('pwdAccountTips') 
        : this.$t('pwdAccountForgetTips');
    },
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);  
      window.addEventListener('popstate', this.popstate, false);
    }
    if (this.$route.query.uid) {
      this.formData.uid = this.$route.query.uid;
    }
  },
  unmounted() {
    window.removeEventListener('popstate', this.popstate, false);
  },
  methods: {
    popstate() {
      this.callbackLogin();
      history.pushState(null, null, document.URL);
    },
    next() {
      // 触发滑块验证
      if (this.captchaObj) {
        this.$refs.captcha.checkFirst();
      }
    },
    verifyUserCheck(proxy) {
      const vm = this;
      vm.$refs.form.validate(valid => {
        if (valid) {
          const uid = vm.formData.uid;
          vm.btnState = true;
          w3Check({uid: uid, uidProxy: proxy}).then(res => {
            if(res && res.length > 1) {
              vm.multiAccount = true;
              vm.multiAccountOptions = res;
            }
            
            if (res && res.length > 0 && res[0].uidProxy) {
              if (!res[0].phoneNumber && !res[0].email) {
                const query = { state: 3, service: this.serviceUrl, tenantId: getQueryVal('tenantId') };
                const queryString = getUrlStr(query);
                this.$router.push(`/pwdTips?${queryString}`)
                return;
              }
              this.validateVal = res[0];

              this.nextPwd()
            }

            if (!res.errorCode) return;

            if (res.errorCode === 'CAS-02-00006') {
              vm.errorState = true;
            }
          })
        }
      })
    },
    nextPwd() {
      const res = this.validateVal;
      this.$emit('changePage', {
        page: this.multiAccount ? 11 : 2, 
        uidType: this.formData.uid,
        uid: res.uidProxy,
        phoneNumber: res.phoneNumber ? res.phoneNumber : '',
        email: res.email ? res.email : '',
        callCountryCode: res.callCountryCode,
        multiAccountOptions: this.multiAccountOptions,
      });
    },
    callbackLogin() {
      const path = callbackLoginMethod(getQueryVal('urlType'))
      if(path == '/account'){
        localStorage.setItem('hisPage', 'hw')
        location.reload()
      }else {
        const query = { service: getQueryValue('service'), tenantId: getQueryVal('tenantId') };
        const queryString = getUrlStr(query);
        this.$router.push(`${path}?${queryString}`);
      }
    },
    changeBtnState() {
      this.btnState = false;
    },
    multiAccountChange(val) {
      this.formData.uid = val;
    },
  },
}
</script>

<style lang="scss" scoped>
@mixin btnBackground($color, $opacity) {
  :deep(.aui-button.aui-button--primary.is-disabled), 
  :deep(.aui-button.aui-button--primary.is-disabled:active), 
  :deep(.aui-button.aui-button--primary.is-disabled:focus), 
  :deep(.aui-button.aui-button--primary.is-disabled:hover) {
    border-color: $color;
    background-color: $color;
    opacity: $opacity;
  }
}

.pwd-account-content{
  .pwd-tips {
    font-size: 14px;
    color: #4a4a4a;
    margin-bottom: 11px;
  }
  .pwd-account {
    // margin-bottom: 32px;
    :deep(.aui-input__inner) {
      height: 40px;
    }
    .account-error {
      color: #f5222d;
      font-size: 12px;
      padding-top: 4px;
    }
  } 
  .pwd-button {
    max-width: none;
    width: 100%;
    height: 40px;
    border-radius: 8px;
  }
  .pwd-account-btn {
    margin-bottom: 12px;

    @include btnBackground(#ea9fa3, 1);
  }
  .callback-login {
    cursor: pointer;
    font-size: 14px;
    color: #4a4a4a;
  }
  :deep(.aui-input-suffix .aui-input__inner) {
    height: 40px;
  }
  :deep(.aui-select) {
    margin-top: 25px;
  }
}

@media screen and (max-width: 767px) {
  .pwd-account-content {
    .pwd-button {
      max-width: none;
      width: 100%;
      height: 36px;
      font-size: 16px;
    }
    .pwd-account-btn {
      margin-bottom: 20px;

      :deep(.aui-button.aui-button--primary) {
        border-color: #f66f6a;
        background: #f66f6a;
      }
      
      @include btnBackground(#f66f6a, 0.4);
    }
    .pwd-mobile-callback {
      background: #fff;
      border: 1px solid #d9d9d9;
      color: #1890ff;
    }
  }
}
</style>