<template>
  <div class="container-pra">
    <div class="header">
      <div id="header_small">
        <a href="javascript:void(0)"> <img
            src="../../assets/img/hw_logo.png"
            class="logo"
            alt="Logo"
            border="0"
          />
        </a>
      </div>
    </div>
    <div class="header-mobile">
      <div class="m_header">
        <div
          class="logo"
          @click="_back"
        >
          <!-- <icon-left-ward></icon-left-ward> -->
          <IconChevronLeft></IconChevronLeft>
          <span>{{ $t('back') }}</span>
        </div>
        {{ $t('ppArgee') }}
        <icon-pause
          class="icon_expand"
          @click="openMenu"
        ></icon-pause>
      </div>
    </div>
    <div
      class="inner"
      v-html='innerHTML'
    >

    </div>
    <!----关闭-->
    <div class="supSubmit">
      <aui-button
        type="primary"
        class=""
        @click="closePage"
      >{{$t('closeButton')}}</aui-button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { Button } from '@aurora/vue'
import { getCookie } from '@/utils/utils'
import { privacyInfo } from '@/api'
import { IconLeftWard, IconChevronLeft } from '@aurora/vue-icon'
export default {
  components: {
    AuiButton: Button,
    IconChevronLeft: IconChevronLeft()
  },
  data() {
    return { // 隐私详情先写死
      innerHTML: '',
      locale: 'zh_CN'
    }
  },
  created() {
    this.locale = this.$i18n.locale
    this.getPrivacyInfo()
  },
  methods: {
    getPrivacyInfo() {
      const _vm = this
      privacyInfo({
        lang: this.locale,
      }).then(res => {
        if (res) {
          _vm.innerHTML = res.content
        }
      })
    },
    privacyAgreement() {
      const _vm = this
      const privacy = '/rest/hwidcenter/get-privacy-statement'
      axios
        .get(privacy, {
          params: {
            language: getCookie('lang').replace('-', '_')
          }
        })
        .then(function(res) {
          document.getElementsByClassName('inner')[0].innerHTML = _vm.innerHTML
        })
        .catch(function(error) {
          document.getElementsByClassName('inner')[0].innerHTML = _vm.innerHTML
        })
    },
    closePage() {
      window.opener = null;
      window.open("about:blank", "_top").close()
    },
    _back() {
      this.$router.go(-1)
    }
  }
}
</script>
<style>
#app .box,
.main {
  /* background: #fff !important; */
}
</style>
<style scoped lang="scss">
.header {
  background: url('../../assets/img/header_bg.gif') repeat-x 0 0;
  height: 63px;
  text-align: left;
  #header_small {
    margin: 0 auto;
    height: 44px;
    padding: 10px 0 9px 10px;
    width: 75%;
    .logo {
      width: 124px;
      height: 22px;
      margin-top: 10px;
      object-fit: contain;
    }
  }
}
.header-mobile {
  display: none;
}
@media screen and (max-width: 767px) {
  .header {
    display: none;
  }
  .header-mobile {
    display: block;
    padding: 8px 27px 12px 16px;
    text-align: center;
    border-bottom: 1px solid #d8d8d8;
    .icon_expand {
      font-size: 20px;
      transform: rotate(-90deg);
      cursor: pointer;
    }
  }
  .m_header {
    display: flex;
    justify-content: space-between;
    .logo {
      flex: 0 0 auto;
      cursor: pointer;
    }
  }
  .supSubmit {
    display: none;
  }
}
.container-pra {
  .title {
    height: 28px;
    font-size: 20px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #000000;
    line-height: 28px;
    margin: 12px 0 24px 0;
  }
  .inner {
    padding: 8px 15px;
    box-sizing: border-box;
    a {
      color: #007dff !important;
    }
    p {
      font-size: 14px;
      font-family: FZLTHJW, FZLTHJW-Regular;
      font-weight: 400;
      text-align: left;
      color: #333333;
      line-height: 18px;
    }
  }
  .supSubmit {
    margin-bottom: 10px;
    text-align: right;
    position: fixed;
    bottom: 3px;
    right: 10%;
  }
}
</style>
