<template>
  <div class="container-pra">
    <div class="header">
      <img
        src="../../assets/img/huawei_white_header.png"
        alt=""
      >
      <p class="title">{{$t('loginWithHuaweiId')}}</p>
    </div>
    <div class="inner">
      <p>{{$t('pravacyText1')}}</p>
      <p><span>{{$t('pravacyText2')}}</span>
        <a
          href="javascript:void(0)"
          @click="privacy"
        >{{$t('hwPrivacy')}}</a>
        <span>{{$t('pravacyText3')}}</span>
        <a
          href="javascript:void(0)"
          @click="privacy"
          style="display: inline-block;"
        >{{$t('hwPrivacy')}}</a>）。
      </p>
      <p>{{$t('pravacyText4')}}</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  data() {
    return {

    }
  },
  methods: {
    privacy() {
      this.$router.push({
        path: '/detailPrivacy'
      })
    }
  }
}
</script>
<style scoped lang="scss">
.container-pra {
  padding: 32px 42px;
  box-sizing: border-box;
  .header {
    text-align: center;
    .title {
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #000000;
      line-height: 28px;
      margin: 12px 0 24px 0;
    }
  }
  .inner {
    a {
      color: #007dff !important;
    }
    p {
      font-size: 14px;
      font-family: FZLTHJW, FZLTHJW-Regular;
      font-weight: 400;
      text-align: left;
      color: #333333;
      line-height: 18px;
    }
  }
}
</style>
