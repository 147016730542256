<template>
  <div class="welcome-box">
    <div class="visibulity"></div>
    <div class="welcome-img" v-if="!errorFlag">{{ $t('loginLoading') }}</div>
    <div class="welcome-img" v-if="errorFlag">{{ errorMsg }}</div>
  </div>
</template>
<script>
import { getQueryValue, getQueryVal, getQueryParam4backend, getUrlStr } from '@/utils/utils'
import { getCallback, getIdmapping } from '@/api'
export default {
  name: 'loginLoading',
  data() {
    return {
      errorMsg: '',
      errorFlag: false,
      tenantId: '',
      service: ''
    }
  },
  created() {
    this.tenantId = getQueryVal('tenantId') || getQueryParam4backend('amp;tenantId')
    this.service = getQueryValue('service')
    this.getLogin()
  },
  methods: {
    getLogin() {
      const data = {
        service: this.service,
        code: this.$route.query.code,
        state: this.$route.query.state,
        ticket: getQueryVal('ticket'),
        currentUrl: encodeURIComponent(window.location.href)
      }

      getCallback(data).then((res) => {
        if (res.code === '0') {
          this.getIdmapping()
        } else {
          this.errorFlag = true
          this.errmessage = res.message
          const query = { service: this.service || '', tenantId: this.tenantId };
          const queryString = getUrlStr(query);
          this.$router.push(`/account?${queryString}`)
        }
      })
    },
    getIdmapping() {
      const data = {
        service: this.service
      }
      getIdmapping(data).then((res) => {
        const { code, data, service } = res || {}
        if (code === '0') {
          if (data && data > 0) {
            const query = {
              pwdRemainDays: data,
              callbackUrl: service,
              service: this.service,
              tenantId: this.tenantId,
              urlType: 0 // 华为账号登录中转页面
            }
            const queryString = getUrlStr(query);
            this.$router.push(`/expiringPassword/index?${queryString}`)
          } else {
            window.location.href = res.service
          }
        } else if (code === 'CAS-02-00012') {
          // 密码已过期
          const query = {
            service: this.service,
            tenantId: this.tenantId,
            urlType: 0 // 华为账号登录中转页面
          }
          const queryString = getUrlStr(query);
          this.$router.push(`/expiringPassword/index?${queryString}`)
        } else {
          this.errorFlag = true
          this.errmessage = res.message
          const query = { service: this.service || '', tenantId: this.tenantId };
          const queryString = getUrlStr(query);
          this.$router.push(`/account?${queryString}`)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.visibulity {
  height: 40px;
}
.welcome-img {
  width: 558px;
  height: 513px;
  background: #ffffff;
  margin: 0 auto;
  text-align: center;
  padding: 50px 0;
  line-height: 450px;
}
</style>
