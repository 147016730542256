<template>
  <div class="bottom_">
    <span>{{message}}</span>
  </div>
</template>
<script>
export default {
  components: {
  },
  props: {
    message: String
  },
  data() {
    return {
      IconWarning: require('../../assets/img/icon_tip.png')
    }
  }
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 767px) {
  // 移动端
  .bottom_ {
    margin-bottom: 8px;
    height: 20px;
    line-height: 20px;
  }
  span {
    font-size: 12px;
    margin-bottom: 8px;
  }
  img {
    width: 12px;
    height: 12px;
  }
}
@media screen and (min-width: 768px) {
  .bottom_ {
    margin-bottom: 8px;
    line-height: 20px;
  }
  span {
    font-size: 12px;
    line-height: 12px;
  }
}
span {
  font-family: PingFangSC-Regular;
  color: #fa2a2d;
  margin-left: 4px;
}
</style>
