<template>
  <div v-show="isShowLogin">
    <div class="visibulity">
       <div class="logo">
        <img :src="logoPc" alt="" v-if="logoPc" />
        <img src='../../assets/img/hw_logo.png' v-else />
      </div>
    </div>
    <div class="login-main">
      <div class="uniportal-Title" :class="{ 'uniportal-TitleEn': lang === 'en-US' }">
        <span>{{$t('uniportalAccountLogin')}}</span>
      </div>
      <div class="uniportal-content">
        <aui-form
          ref="form"
          :model="formData"
          :rules="rules"
          label-width="0"
          validate-type="text"
        > 
          <!-- 账号登录 -->
          <aui-form-item
            label=""
            prop="loginName"
            v-show="!isSMSLogin"
          >
            <aui-input
              v-model="formData.loginName"
              :placeholder="$t('setAccountPlaceholder')"
            ></aui-input>
          </aui-form-item>
          <aui-form-item
            label=""
            prop="uidMaskKey"
            v-show="!isSMSLogin && moreAccountState"
          >
            <aui-select 
              v-model="formData.uidMaskKey" 
              :placeholder="$t('pleaseSelect')"
            >
              <aui-option 
                v-for="item in MoreAccountOptions" 
                :key="item.maskKey" 
                :label="item.maskUid" 
                :value="item.maskKey">
              </aui-option>
            </aui-select>
          </aui-form-item>
          <aui-form-item
            label=""
            prop="password"
            v-show="!isSMSLogin"
            style="position: relative;"
          >
            <ssoPwd
              v-model="formData.password"
              :placeholder="$t('password')"
            ></ssoPwd>
            <div class="web_show" v-if="!isSMSLogin">
              <sso-error
                v-if="checkSSOerror"
                :message="webError"
              ></sso-error>
            </div>
          </aui-form-item>
          <!-- 短信登录 -->
          <aui-form-item
            label=""
            prop="phoneNumber"
            v-show="isSMSLogin"
            class="phone-input-content"
          >
            <aui-input 
              class="phone-input"
              :placeholder="$t('mobileNumber')" 
              v-model="formData.phoneNumber"
            >
              <template #prefix>
                <aui-select 
                  v-model="formData.callCountryCode" 
                  :placeholder="$t('pleaseSelect')"
                  class="choose-country"
                >
                  <aui-option 
                    v-for="item in callCountryCodeOptions" 
                    :key="item.countryCode" 
                    :label="item.callCountryCodeText" 
                    :value="item.countryCode">
                  </aui-option>
                </aui-select>
              </template>
            </aui-input>
          </aui-form-item>
          <aui-form-item
            label=""
            prop="verifyCode"
            v-show="isSMSLogin"
            style="position: relative;"
          >
            <aui-input :placeholder="$t('smsVerification')" v-model="formData.verifyCode">
              <template #suffix>
                <span @click="getVerificationCode" class="account-code" v-show="codeBtnShow">
                  {{ firstGetCode ? $t('getCode') : $t('resend') }}
                </span>
                <span 
                  class="account-code account-again-code" 
                  v-show="!codeBtnShow" 
                  v-html="$t('againCode', {time: count})">
                </span>
              </template>
            </aui-input>
            <div class="web_show" v-if="isSMSLogin">
              <sso-error
                v-if="checkSSOerror"
                :message="webError"
              ></sso-error>
            </div>
            <div class="error-tips" v-if="isShowErrorTips">{{ errorTips }}</div>
            <div class="dialogBox" v-if="showCodeTip">
							<span>{{ $t('smsSendTo') + formData.phoneNumber  + $t('tenMinutes') }}</span>
						</div>
          </aui-form-item>
          <aui-form-item>
            <div @click="changeLoginMethod" class="login-type-btn">
              {{ loginMehod }}
            </div>
          </aui-form-item>
          <aui-form-item>
            <aui-button
              type="primary"
              class="aui-btn-full"
              @click="handClickSubmit"
              :disabled="loginBtnDisabled"
            >{{$t('login')}}</aui-button>
          </aui-form-item>
        </aui-form>
        <div class="btn-list">
          <!-- <div class="btn-list-li" @click="registration">
            {{ $t('registration') }}
          </div> -->
          <div class="btn-list-pwd">
            <div class="btn-list-pwd-li" @click="registration">
              {{ $t('registration') }}
            </div>
            <i class="btn-line"></i>
            <div
              class="btn-list-pwd-li"
              @click="forgetPassword"
            >
              {{$t('forgetPwd')}}
            </div>
            <i class="btn-line"></i>
            <div
              class="btn-list-pwd-li"
              @click="updatePassword"
            >
              {{$t('modifyPwd')}}
            </div>
          </div>
        </div>
        <div class="hwLogin_url" v-if="false">
          <div class="line">
            <span></span>
            <span>{{ $t('otherLogin') }}</span>
            <span></span>
          </div>
          <div class="img-content" @click="hwLogin">
            <img src="../../assets/img/hwlogo-icon.png" />
          </div>
          <a href="javascript:void(0)" class="hwLogin"  @click="hwLogin">
            {{$t('hwAccount')}}
          </a>
        </div>
        <div class="help-content">
          <icon-help-solid class="aui-svg-size icon-help-solid help-icon"></icon-help-solid>
          <p class="help-title" @click="gotoHelp">{{ $t('helpTips') }}</p>
        </div>
      </div>
    </div>

    <aui-dialog-box 
      :show-header="false"
      :visible="boxVisibility" 
      @update:visible="boxVisibility = $event"
      :close-on-press-escape="false"
      width="90%">
      <Certification  
        :serviceUrl="serviceUrl" 
        :uidProxy="uidProxy" 
        :isSecond="isSecond"  
        v-if="boxVisibility"
        fromUrl="uniportalAccount"
        @handleSendCodeTips="handleSendCodeTips"
        @handleCloseCertifica="handleCloseCertifica" />

      <div class="send-info-tips" v-if="sendTipsInfoState">
        <icon-success class="aui-svg-size icon-success success-img"></icon-success>
        <span>{{ sendTipsInfo }}</span>
      </div>
    </aui-dialog-box>
  </div>
</template>

<script>
import {
  Form,
  FormItem,
  Input,
  Button,
  Modal,
  DialogBox,
  Select,
  Option
} from '@aurora/vue';
import ssoPwd from '@/components/form/ssoPwd'
import ssoError from '@/components/form/ssoError'
import Certification from '@/views/certification/Certification'
import {
  uniportalLogin, 
  setUniportalLink,
  checklogin,
  smsLoginSmsSendFn,
  smsLoginFn,
  getCountryCodeList,
} from '@/api'
import { 
  getQueryValue,
  isMobile,
  getQueryVal,
  getCookie,
  checkUserRange,
  checkUserRangeCatch,
  setErrorMessage,
  checkPhone,
  getFpData,
  getUrlStr,
} from '@/utils/utils'
import { IconSuccess, IconHelpSolid } from '@aurora/vue-icon'

export default {
  name: 'uniportalAccount',
  inject: ['reload'],
  data() {
    const vm = this
    const accountBlur = (rule, value, callback) => {
      if (vm.formData.loginName === '') {
        vm.isUserId = false
        callback()
      } else {
        vm.isUserId = false
        vm.message = ''
        callback()
      }
    }
    const passwordBlur = (rule, value, callback) => {
      vm.pwdShow = true
      if (vm.formData.password === '') {
        vm.isUserId = false
        callback()
      } else if (vm.formData.password.length < 8 || vm.formData.password.length > 60) {
        vm.isUserId = true
        vm.message = vm.$t('pwdTip')
        callback(new Error(' '))
      } else {
        vm.isUserId = false
        vm.pwdShow = false
        vm.message = ''
        callback()
      }
    }
    const passwordKeyUp = function(rule, value, callback) {
      if (value.match(/[^\x00-\xff]/gi)) {
        // 密码中不能包含中文或其他多字节字符
        vm.formData.password = ''
        vm.pwdShow = true
      } else if (vm.formData.password.length > 7 && vm.formData.password.length < 60) {
        vm.pwdShow = false
      }
      callback()
    }
    const phoneNumberBlur = (rule, value, callback) => {
      if (vm.formData.phoneNumber === '') {
        vm.isUserId = false
        callback()
      } else {
        if (!checkPhone(vm.formData.phoneNumber)) {
          callback(vm.$t('mobileNumberFormatTip'));
          return;
        }
        vm.isUserId = false
        vm.message = ''
        callback()
      }
    }
    const verifyCodeBlur = (rule, value, callback) => {
      if (vm.formData.verifyCode === '') {
        vm.isUserId = false
        callback()
      } else {
        vm.isUserId = false
        vm.message = ''
        callback()
      }
    }
    return {
      formData: {
        loginName: '',
        account: '',
        password: '',
        service: '',
        callCountryCode: '',
        phoneNumber: '',
        verifyCode: '',
        uidMaskKey: '',
      },
      source: 'SMS',
      rules: {
        loginName: [{ validator: accountBlur, trigger: 'blur' }],
        password: [
          { validator: passwordBlur, trigger: 'blur' }, 
          { validator: passwordKeyUp, trigger: 'change' }
        ],
        phoneNumber: [{ validator: phoneNumberBlur, trigger: 'blur' }],
        verifyCode: [{ validator: verifyCodeBlur, trigger: 'blur' }],
      },
      isSubmit: false,
      message: '',
      lockedTime: 0,
      failLogonCount: 0,
      isUserId: false,
      pwdShow: true,
      linkUrlList: [],
      serviceUrl: '',
      boxVisibility: false,
      uidProxy: '',
      isSecond: false,
      sendTipsInfoState: false, 
      sendTipsInfo: '',
      lang: getCookie('lang').replace('_', '-'),
      isSMSLogin: false,
      callCountryCodeOptions: [],
      codeBtnShow: true,
      count: '',
      timer: null,
      loginMehod: vm.$t('smsLoginBtn'),
      loginBtnDisabled: true,
      moreAccountState: false,
      MoreAccountOptions: [],
      isShowErrorTips: false,
      errorTips: '',
      deviceFingerInfo: '',
      firstGetCode: true,
      showCodeTip: false,
      threetime: 3,
      isShowLogin: false,
    }
  },
  components: {
    AuiForm: Form,
    AuiFormItem: FormItem,
    AuiInput: Input,
    AuiButton: Button,
    AuiSelect: Select,
    AuiOption: Option,
    AuiDialogBox: DialogBox,
    ssoPwd,
    ssoError,
    IconHelpSolid: IconHelpSolid(),
    IconSuccess: IconSuccess(),
    Certification
  },
  props: {
    logoPc: {
      type: String,
      default: ''
    },
    encryptedPasswordSwitch: {
      type: String,
      default: ''
    },
  },
  watch: {
    checkFormData() {
      this.isnNextBtn();
    },
    checkSSOerror(val) {
      if (val.isUserId || val.failLogonCount > 0 || val.lockedTime > 0) {
        return true;
      }
      return false;
    },
    isSMSLogin() {
      this.isUserId = false;
      this.lockedTime = 0;
      this.failLogonCount = 0;
    },
  },
  computed: {
    loginFailCounter: function() {
      return this.$t('accountErrpr') + ' ' + this.failLogonCount + ' ' + this.$t('times')
    },
    lockedTimeCounter: function() {
      return this.$t('errorTimes') + ' ' + this.lockedTime + ' ' + this.$t('Seconds')
    },
    webError: function() {
      if (this.failLogonCount) {
        return this.loginFailCounter
      } else if (this.lockedTime) {
        return this.lockedTimeCounter
      } else if (this.message) {
        return this.message
      }
      return ''
    },
    checkFormData() {
      if (this.source === 'SMS') {
        const { loginName, password, uidMaskKey } = this.formData;
        if (this.moreAccountState) {
          return { loginName, password, uidMaskKey };
        }
        return { loginName, password };
      }
      if (this.source === 'ACCOUNT') {
        const { callCountryCode, phoneNumber, verifyCode } = this.formData;
        return { callCountryCode, phoneNumber, verifyCode };
      }
    },
    checkSSOerror: function() {
      const isUserId = this.isUserId;
      const failLogonCount = this.failLogonCount;
      const lockedTime = this.lockedTime;
      return {isUserId, failLogonCount, lockedTime};
    },
  },
  created() {
    if (localStorage.getItem('casLoginType') && localStorage.getItem('casLoginType') === 'uniportal') {
      localStorage.removeItem('uniportal')
    }

    // 按下回车执行登录按钮点击事件
    var _self = this
    document.onkeydown = function(e) {
      var key = window.event.keyCode
      if (key === 13) {
        _self.submit()
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.checkloginFn();
    }, 1000)
    this.getCountryCodeListData();
    if (this.$route.query.service) {
      this.formData.service = getQueryValue('service');
      this.serviceUrl = getQueryValue('service');
    }
  },
  methods: {
    checkloginFn() {
      let fp = getFpData();
      checklogin({fingerPrint: fp}).then(res => {
        // 是否进入到二次认证
        if (res.callbackUrl && res.callbackUrl.indexOf("#/account?") < 0) {
          window.localStorage && window.localStorage.setItem('hisPage', 'uniportal');
          location.href = res.callbackUrl;
          return;
        }
        if (res.accountInfo && this.checkTriggeringAuth(res.accountInfo.triggeringAuth)) {
          const { triggeringAuth, uid } = res.accountInfo;
          this.checkSecond(triggeringAuth, res.callbackUrl, uid);
        }
        if (res.errorCode) {
          checkUserRangeCatch(res, this);
        }
        if (!res.errorCode && !res.accountInfo) {
          this.isShowLogin = true;
        }
      }).catch(() => {
        this.isShowLogin = true;
      })
    },
    hwLogin() {
      localStorage.setItem('hisPage', 'hw');
      location.reload();
    },
    closeTip() {
      this.lockedTime = 0
      this.failLogonCount = 0
      this.message = ''
      this.isUserId = false
    },
    handClickSubmit() {
      if (this.source === 'SMS') {
        this.submit();
      } else if (this.source === 'ACCOUNT') {
        this.smsLogin();
      }
    },
    // 短信登录
    smsLogin() {
      const formData = JSON.parse(JSON.stringify(this.formData));
      let fp = getFpData();
      const params = {
        callCountryCode: formData.callCountryCode,
        phoneNumber: formData.phoneNumber,
        source: '1',
        service: formData.service,
        verifyCode: formData.verifyCode,
        fingerPrint: fp
      };

      smsLoginFn(params).then(res => {
        if (res.accountInfo && this.checkTriggeringAuth(res.accountInfo.triggeringAuth)) {
          const { triggeringAuth, uid } = res.accountInfo;
          this.checkSecond(triggeringAuth, res.callbackUrl, uid);
        }

        if (res.errorCode) {
          if (res.errorCode === 'CAS-07-00001') {
            this.failLockCommon(res);
            return;
          }
          this.isUserId = true;
          this.message = setErrorMessage(res.errorCode);
        }
      })
    },
    // 登录密码转byte字符串方法
    toByte(str){
      let bytes = new Array();
      let len, c;
      len = str.length;
      for (let i = 0; i < len; i++) {
        c = str.charCodeAt(i);
        if (c >= 0x010000 && c <= 0x10FFFF) {
          bytes.push(((c >> 18) & 0x07) | 0xF0);
          bytes.push(((c >> 12) & 0x3F) | 0x80);
          bytes.push(((c >> 6) & 0x3F) | 0x80);
          bytes.push((c & 0x3F) | 0x80);
        } else if (c >= 0x000800 && c <= 0x00FFFF) {
          bytes.push(((c >> 12) & 0x0F) | 0xE0);
          bytes.push(((c >> 6) & 0x3F) | 0x80);
          bytes.push((c & 0x3F) | 0x80);
        } else if (c >= 0x000080 && c <= 0x0007FF) {
          bytes.push(((c >> 6) & 0x1F) | 0xC0);
          bytes.push((c & 0x3F) | 0x80);
        } else {
          bytes.push(c & 0xFF);
        }
      }
      return bytes+'';
    },
    // 帐号登录
    submit() {
      const _this = this;
      _this.isSubmit = true;
      _this.closeTip();
      _this.isUserId = false;
      this.$refs.form.validate(valid => {
        if (valid) {
          const paramsData = JSON.parse(JSON.stringify(_this.formData));
          let fp = getFpData();
          const params = {
            loginName: paramsData.loginName,
            account: paramsData.account,
            password: this.encryptedPasswordSwitch==='on' ? this.toByte(paramsData.password):paramsData.password,
            service: paramsData.service,
            fingerPrint: fp,
            encryptedPasswordSwitch:this.encryptedPasswordSwitch
          }
          if (this.moreAccountState) {
            params.uidMaskKey = paramsData.uidMaskKey;
          }
          uniportalLogin(params).then(res => {
            // 是否进入到二次认证
            if (res.accountInfo && this.checkTriggeringAuth(res.accountInfo.triggeringAuth)) {
              const { triggeringAuth, uid, pwdRemainDays } = res.accountInfo;
              this.checkSecond(triggeringAuth, res.callbackUrl, uid, pwdRemainDays);
              return;
            }

            if (!res.errorCode) return;

            switch (res.errorCode) {
              case 'CAS-02-00008':
                // 帐号已被锁定
                _this.failLockCommon(res);
                break;
              case 'CAS-02-00012':
                _this.pwdHasExpired();
                // 密码已过期
                break;
              case 'CAS-02-00037':
                _this.completeUserInfo();
                // 完善信息
                break;
              case 'CAS-02-00011':
                // 账号或密码错误
                _this.failLockCommon(res);
                break;
              case 'HWIDLOGIN_000005':
                _this.isUserId = true
                _this.message = _this.$t('passwordEnd')
                // 密码已过期
                break;
              case 'CAS-03-00006':
                _this.isUserId = true
                _this.message = _this.$t('serviceNotFound')
                // 服务未注册
                break;
              case 'CAS-02-00007':
                // 账号存在一对多的情况
                _this.setMoreAccountOptions(res);
                break;  
              case 'CAS-02-00038': // uid已过期
              case 'CAS-03-00008': // service
              case 'CAS-02-00040':
                _this.isUserId = true
                _this.message = setErrorMessage(res.errorCode);
                break;
              default:
                _this.isUserId = true
            }
          })
        }
      })
    },
    gotoSecond(uid, isSecond) {
      localStorage.setItem('casLoginType', 'uniportal');
      // urlType 是uniportal登录
      let query = { urlType: 1, uid, isSecond, tenantId: getQueryVal('tenantId'), service: this.serviceUrl };
      const queryString = getUrlStr(query);
      this.$router.push(`/certification?${queryString}`)
    },
    checkSecond(triggeringAuthVal, callbackUrl, uid, pwdRemainDays) {
      if (triggeringAuthVal === 0) {
        if (pwdRemainDays) {
          this.pwdWillExpired(pwdRemainDays, callbackUrl);
          return;
        }
        // 放行
        if (callbackUrl) {
          window.localStorage && window.localStorage.setItem('hisPage', 'uniportal');
          checkUserRange(callbackUrl, this, '');
        }
      } else if (triggeringAuthVal === 1 || triggeringAuthVal === 2) {
        // 二次认证和风控认证
        const state = triggeringAuthVal === 1;
        localStorage.setItem('toCasUrl', location.href);
        if (isMobile()) {
          this.boxVisibility = true;
          this.uidProxy = uid;
          this.isSecond = state;
          return;
        }
        this.gotoSecond(uid, state);
      } else if (triggeringAuthVal === 3 || triggeringAuthVal === 4) {
        // 应用侧拒绝和风控拒绝
        Modal.message({
          message: this.$t('loginRefused'),
          status: 'error',
          duration: '1500'
        });
      }
    },
    getVerificationCode() {
      const formData = JSON.parse(JSON.stringify(this.formData));
      this.isUserId = false;
      this.failLogonCount = 0;
      this.lockedTime = 0;
      if (!formData.callCountryCode) {
        this.isUserId = true;
        this.message = this.$t('countryCode');
        return;
      } 
      if (!formData.phoneNumber) {
        this.isUserId = true;
        this.message = this.$t('mobileNumberEmptyTip');
        return;
      }
      if (!checkPhone(formData.phoneNumber)) {
        return;
      }
      if (this.isShowErrorTips) {
        this.isShowErrorTips = false;
        this.errorTips = '';
      }
      const params = {
        callCountryCode: formData.callCountryCode,
        phoneNumber: formData.phoneNumber,
        source: '1',
        service: formData.service,
      };
      let vm = this;
      smsLoginSmsSendFn(params).then(res => {
        if (!res.errorCode) {
          this.getCodeNum();
          this.firstGetCode = false;
          vm.showCodeTip = true
          let threetimer = setInterval (function () {
					  if (vm.threetime == 1) {
						  clearInterval(threetimer)
						  vm.showCodeTip = false
						  vm.threetime = 3
					  } else {
						  vm.threetime--
					  }
				  }, 1000)
          return;
        }

        if (res.errorCode) {
          if (res.errorCode === 'CAS-07-00010') {
            this.isShowErrorTips = true;
            this.errorTips = setErrorMessage(res.errorCode);
            return;
          } else if (['CAS-07-00009', 'CAS-07-00031', 'CAS-07-00032'].indexOf(res.errorCode) > -1) {
            this.isShowErrorTips = true;
            this.errorTips = setErrorMessage(res.errorCode);
            return;
          }
          this.isUserId = true;
          this.message = setErrorMessage(res.errorCode);
        }
      })
    },
    handleCloseCertifica() {
      this.boxVisibility = false;
    },
    handleSendCodeTips(data) {
      const _this = this;
      _this.sendTipsInfoState = true; 
      if (data.type === 'sms') {
        _this.sendTipsInfo = _this.$t('sendSmsTips1') + data.sendAccountInfo + _this.$t('sendSmsTips2');
      } else if (data.type === 'email') {
        _this.sendTipsInfo = _this.$t('sendEmailTips1') + data.sendAccountInfo + _this.$t('sendSmsTips2');
      }

      setTimeout(function() {
        _this.sendTipsInfoState = false;
        _this.sendTipsInfo = '';
      }, 1500);
    },
    failLockCommon(res) {
      if (res.errorData.lockedTime > 0) {
        this.failLogonCount = 0
        this.lockedTime = Math.ceil(res.errorData.lockedTime / 1000)
        this.timerAction()
      } else {
        this.failLogonCount = 5 - res.errorData.failLogonCount || 5
      }
    },
    timerAction() {
      const self = this
      clearInterval(self.threetimer)
      self.threetimer = null
      self.threetimer = setInterval(() => {
        if (self.lockedTime <= 0) {
          clearInterval(self.threetimer)
          self.lockedTime = 0
          return
        }
        self.lockedTime--
      }, 1000)
    },
    // 修改密码
    updatePassword() {
      let query = { type: 1, service: this.serviceUrl, tenantId: this.tenantId, urlType: 1};
      if (this.formData.userId) {
        query.uid = this.formData.userId;
      }
      const queryString = getUrlStr(query);
      this.$router.push(`/resetPwd?${queryString}`);
    },
    // 忘记密码
    forgetPassword() {
      let query = { type: 2, service: this.serviceUrl, tenantId: this.tenantId, urlType: 1 };
      if (this.formData.userId) {
        query.uid = this.formData.userId;
      }
      const queryString = getUrlStr(query);
      this.$router.push(`/resetPwd?${queryString}`);
    },
    // 注册
    registration() {
      if (isMobile()) {
        this.gotoUniportal('cas_uniportal_registration_mobile');
      } else {
        this.gotoUniportal('cas_uniportal_registration');
      }
    },
    // 帮助页面
    gotoHelp() {
      this.gotoUniportal('cas_uniportal_help');
    },
    // 密码已过期
    pwdHasExpired() {
      const query = {
        service: this.serviceUrl,
        tenantId: this.tenantId
      }
      const queryString = getUrlStr(query);
      this.$router.push(`/expiredPassword/index?${queryString}`)
    },
    // 密码即将过期
    pwdWillExpired(pwdRemainDays, callbackUrl) {
      const query = {
        pwdRemainDays,
        callbackUrl: encodeURIComponent(callbackUrl),
        service: this.serviceUrl,
        tenantId: this.tenantId
      }
      const queryString = getUrlStr(query);
      this.$router.push(`/expiringPassword/index?${queryString}`)
    },
    // 用户完善信息
    completeUserInfo() {
      this.gotoUniportal('cas_uniportal_complete_userinfo');
    },
    gotoUniportal(link) {
      setUniportalLink(link).then(url => {
        if (['cas_uniportal_registration_mobile', 'cas_uniportal_registration'].includes(link)) {
          window.location.href = url + '?caslogin=1&redirect=' + encodeURIComponent(location.href) + 
            '&lang=' + getCookie('lang');
        } else if (link === 'cas_uniportal_help') {
          window.open(url + '?caslogin=1&redirect=' + encodeURIComponent(location.href), '_blank');
        } else if (link === 'cas_uniportal_pwd_has_expired') {
          window.location.href = url + '?caslogin=1&redirect=' + encodeURIComponent(location.href);
        } else if (link === 'cas_uniportal_complete_userinfo') {
          window.location.href = url;
        }
      })
    },
    checkTriggeringAuth(data) {
      if (!data && data != 0) {
        return false;
      } 
      return true;
    },
    getCodeNum() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.codeBtnShow = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.codeBtnShow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)
      }
    },
    getCountryCodeListData() {
      getCountryCodeList().then(res => {
        if(Object.keys(res).length) {
          let list = [];
          for (var i in res) {
            for (var j = 0; j < res[i].length; j++) {
              list.push(res[i][j])
            }
          }
          this.countryCodeFilter(list);
        }
      })
    },
    countryCodeFilter(list) {
      const query = '';
      let arr = list.filter((item) => {
        if (item && item.areaCode) {
          item.areaCode = 0 == item.areaCode.indexOf('+') ? item.areaCode : '+' + item.areaCode;
          item['callCountryCodeText'] = item.areaCode + ' ' + 
            ('zh-CN' == this.lang ? item.nameCn : item.nameEn);

          if (item.countryCode === '0086') {
            this.formData.callCountryCode = '0086';
          }

          return -1 != item.areaCode.indexOf(query) || -1 != item.twoCode.indexOf(query);
        }
      })
      this.callCountryCodeOptions = arr;
    },
    setMoreAccountOptions(res) {
      this.isUserId = true;
      this.message = setErrorMessage(res.errorCode);
      this.moreAccountState = true;
      this.MoreAccountOptions = res.accountInfo;
    },
    changeLoginMethod() {
      if (this.isUserId) {
        this.isUserId = false;
        this.message = '';
      }
      if (this.isShowErrorTips) {
        this.isShowErrorTips = false;
        this.errorTips = '';
      }
      if (this.source === 'SMS') {
        this.source = 'ACCOUNT';
        this.loginMehod = this.$t('accountLoginBtn');
        this.isSMSLogin = true;
      } else if (this.source === 'ACCOUNT') {
        this.source = 'SMS';
        this.loginMehod = this.$t('smsLoginBtn');
        this.isSMSLogin = false;
      }
    },
    isnNextBtn() {
      const _vm = this;
      let dataState = false;
      if (this.source === 'SMS') {
        const { loginName, password, uidMaskKey } = _vm.formData;
        if (this.moreAccountState) {
          dataState = loginName && password && uidMaskKey;
        } else {
          dataState = loginName && password;
        }
      }
      if (this.source === 'ACCOUNT') {
        const { callCountryCode, phoneNumber, verifyCode } = _vm.formData;
        const phoneNumberState = phoneNumber ? checkPhone(phoneNumber) : phoneNumber;
        dataState = callCountryCode && phoneNumberState && verifyCode;
      }
      if (dataState) {
        _vm.loginBtnDisabled = false;
      } else {
        _vm.loginBtnDisabled = true;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.visibulity {
  height: 120px;
  .logo {
    height: 60px;
    padding-left: 200px;
    line-height: 60px;
    background-color: #ffffff ;
    img {
      height: 40px;
      margin: 10px auto;
    }
  }
}

:deep(input::-webkit-input-placeholder) {
  font-size: 14px;
  color: #9B9DA2 !important;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
}

:deep(input:-ms-input-placeholder) {
  font-size: 14px;
  color: #9B9DA2 !important;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
}

:deep(input::-moz-placeholder) {
  font-size: 14px;
  color: #9B9DA2 !important;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
}

:deep(input:-moz-placeholder) {
  font-size: 14px;
  color: #9B9DA2 !important;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
}

.login-main {
  width: 526px;
  margin: 0 auto;
  height: auto;
  background: #fff;
  padding: 64px 48px 48px 48px;
  box-sizing: border-box;
  border-radius: 4px;
  .hwidsdk_mudule {
    height: 300px;
    text-align: center;
  }
  .uniportal-Title {
    text-align: center;
    margin-bottom: 20px;
    span {
      display: inline-block;
      width: 285px;
      height: 42px;
      font-size: 32px;
      font-family: Microsoft Yahei, STHeiti, Simsun, STSong, Helvetica Neue, Helvetica, Arial, sans-serif;
      text-align: center;
      color: #191919;
      line-height: 40px;
      margin-bottom: 20px;
    }
  }
  .uniportal-TitleEn > span {
    font-size: 24px;
  }
  .uniportal-content {
    .web_show {
      position: absolute;
    }
    .aui-btn-full {
      height: 48px;
    }
    .btn-list {
      display: flex;
      justify-content: center;
      font-size: 14px;
      color: #007dff;

      .btn-list-li {
        cursor: pointer;
      }

      .btn-list-pwd {
        display: flex;

        .btn-list-pwd-li {
          cursor: pointer;
        }
        .btn-line {
          width: 1px;
          height: 10px;
          background: #b9b2b2;
          margin: 5px 8px 0;
        }
      }
    }
    .line {
      display: flex;
      span:nth-child(1),
      span:nth-child(3) {
        display: inline-block;
        border-top: 1px solid #ccc;
        margin-top: 7px;
        flex: auto;
      }
      span:nth-child(2) {
        height: 14px;
        opacity: 0.6;
        font-size: 12px;
        font-family: HarmonyHeiTi, HarmonyHeiTi-Regular;
        font-weight: 400;
        text-align: center;
        color: #1a1a1a;
        line-height: 14px;
        margin: 0 8px;
      }
    }
    .hwLogin_url {
      margin-top: 32px;

      .img-content {
        cursor: pointer;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: #f2f2f2;
        margin: 20px auto 2px;

        >img {
          margin: 5px;
          width: 26px;
        }
      }

      .hwLogin {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 12px;
        color: #767676;
      }
    }
    .help-content {
      overflow: hidden;
      display: flex;
      justify-content: center;
      margin-top: 100px;
      .help-icon {
        fill: #007dff;
        margin-top: 1px;
        margin-right: 3px;
      }
      .help-title {
        cursor: pointer;
        font-size: 14px;
        color: #808080;
        margin: 0;
      }
    }
  }
  .phone-input-content .phone-input{
    :deep(.aui-input__inner) {
      padding-left: 180px;
    }
  }
  :deep(.aui-input-prefix .aui-input__inner) {
    padding-right: 0;
  }
  .choose-country {
    :deep(.aui-input-suffix) {
      width: 150px;
    }
    :deep(.aui-input-suffix::after) {
      content: '';
      width: 1px;
      height: 20px;
      border-left: 1px solid #d8d8d8;
    }
    :deep(.aui-input-suffix .aui-input__inner) {
      border: none;
      height: 34px;
      padding-left: 10px;
    }
  }
  .login-type-btn {
    float: right;
    cursor: pointer;
    color: #007DFF;
  }
  .account-code {
    color: #007DFF;
  } 
  .account-again-code {
    cursor: default;
  }
  .error-tips {
    color: #f5222d;
    font-size: 12px;
    padding-top: 4px;
  }
}
@media screen and (max-width: 767px) {
  .login-main {
    width: auto;
    margin: 0 auto;
    height: auto;
    background: #fff;
    padding: 32px 24px 10px 24px;
    .uniportal-Title {
      margin-bottom: 40px;
      span {
        height: 32px;
        font-size: 28px;
        font-family: Source Han Sans CN, Source Han Sans CN-Medium;
        font-weight: 500;
        text-align: center;
        color: #000000;
        line-height: 32px;
        margin: 30px 0 0 0;
      }
    }
    .uniportal-content {
      .aui-btn-full {
        height: 44px;
        border-radius: 22px;
        margin-top: 10px;
      }
      .btn-list {
        margin-top: 8px;
      }
      .help-content {
        position: fixed;
        bottom: 7%;
        z-index: 100;
        left: 50%;
        transform: translateX(-50%);
      }
      .phone-input-content .phone-input{
        :deep(.aui-input__inner) {
          padding-left: 150px;
        }
      }
      .choose-country {
        :deep(.aui-input-suffix) {
          width: 140px;
        }
        :deep(.aui-input-suffix::after) {
          content: '';
          width: 1px;
          height: 20px;
          border-left: none;
        }
        :deep(.aui-input-suffix .aui-input__inner) {
          border: none;
          height: 34px !important;
          padding-left: 0px;
        }
      }
    }
  }
  :deep(input.aui-input__inner) {
    height: 48px;
  }
  .visibulity {
    height: 40px;
  }
  .login-type-btn {
    margin-top: 10px;
  }
  :deep(.aui-dialog-box) {
    position: absolute;
    width: 90% !important;
    left: 0 !important;
    right: 0 !important;
    top: 15% !important;
    margin: auto;
    border-radius: 6px;
    overflow: visible;
  }
  :deep(.aui-dialog-box .aui-dialog-box__body) {
    padding: 0;

    .send-info-tips {
      position: absolute;
      bottom: -65px;
      width: 100%;
      background-color: #fff;
      border-radius: 6px;
      box-sizing: border-box;
      padding: 10px 15px;

      .success-img {
        fill: #52c41a;
        margin-right: 5px;
      }

      > span {
        font-size: 12px;
      }
    }
  }
}
.dialogBox {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  display: block;
  z-index: 9999;
  width: 320px;
  min-height: 40px;
  box-shadow: 0px 2px 6px 0px #bfbfbf;
  display: flex;
  align-items: center;
	justify-content: center;
  padding: 12px 16px;
  border-radius: 2px;
  box-sizing: border-box;
}
.dialogBox span {
  font-size: 12px;
  font-family: Microsoft YaHei, Microsoft YaHei-Regular;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}
</style>

<style>
@media screen and (max-width: 767px) {
  .aui-grid-modal__wrapper.type__message .aui-grid-modal__body {
    padding: 10px !important;
  }
}
.login-main input.aui-input__inner {
  padding-right: 16px;
}
.login-main .aui-input__suffix {
  right: 16px;
}
</style>