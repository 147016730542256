<template>
  <div class="container-pra">
    <div class="header">
      <img
        src="../../assets/img/huawei_white_header.png"
        alt=""
      >
      <p class="title">{{$t('loginWithHuaweiId')}}</p>
    </div>
    <div class="inner">

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { getCookie } from '@/utils/utils'
export default {
  components: {

  },
  data() {
    return { // 隐私详情先写死
      innerHTML: `<p>
        “华为产品“是由华为技术有限公司（以下称“我们”“华为”）为您提供的基础服务软件，或帮助您，更好地使用华为应用、服务、
        网站或其他非华为应用华为设备上统一使用和保护登录用户体验的标准，我们为个人服务的个人信息的法律要求非常隐私和成熟
        的个人保护信息，为您提供相应的安全保护信息。
      </p>
      <h3>版本更新说明</h3>
      <p>请您仔细阅读隐私声明的更新内容：</p>
      <p>•“我们如何收集和使用您的个人信息”中，根据个人信息处理的基础进行了统一刷新。</p>
      <p>•“增加对儿童个人的保护信息”，处理更多个人的信息。</p>
      <h3>重要提醒</h3>
      <p>•“华为业务”作为华为业务的通行凭证，在您的应用中，“华为云”、“华为云会议”、“华为视频”、“华为音乐”等业务，以及华为终端类设备及相关合作伙伴服务时，为您提供一致的用户体验和安全认证。</p>
      <p>•为了使用您的华为服务，在快捷安全的登录体验中，您提供的授权信息、我们公司可能会在华为的关联内部共享使用。只会出于特定、目的、目的在华为的关联公司提供内部使用服务所需的信息，并提供相关信息。</p>
      <p>•国家要求的实名认证（包括本地控制、银行卡）会加密提供给相关机构进行核安全认证。 ，冒犯等行为并减少风险。</p>
      <p>•华为申请/服务华为华为申请服务进行鉴权访问时，会提前确认您的协议签署记录，您可以通过正常访问权限。</p>
      <p>•“华为启动授权”在您使用华为或应用程序时，可能会被应用程序关联的运行。</p>
      <h3>1 我们如何收集和使用您的个人信息</h3>
      <p>1.1 您将通过本服务提供给您的基本服务，在您的使用过程中，我们会为您提供基本服务，我们会为您提供以下服务所必须的信息，以履行我们的义务，确保使用本应用程序相关功能。</p>
      <p>1.1.1 域名功能 </p>
      <p>注册与登录 </p>
      <p>华为注册时用于注册时提供手机号码或邮箱，在设置密码时提供全部注册申请，
        同时努力为您提供保护的信息，您还可以使用华为注册人，需要通过您的生日来完成您的生日。泡芙称、基础、性别、会员名等信息获得更好的产品使用体验。 </p>
      <p>在或登录过程中，我们会同时收集您的D、设备信息（如：IMEI）、设备信息、运营网络信息（如：UUI、设备信息）、位置信息以及设备设置信息（如涉及）
        以方便您在常用设备或信任浏览器上快速登录。 </p>
      <p>此外，您还可以使用脸或信息来替代人的密码完成登录，这些信息仅保存在您设备的安全可信区域中。</p>`
    }
  },
  created() {
    this.privacyAgreement()
  },
  methods: {
    privacyAgreement() {
      const _vm = this
      const privacy = '/rest/hwidcenter/get-privacy-statement'
      axios
        .get(privacy, {
          params: {
            language: getCookie('lang').replace('-', '_')
          }
        })
        .then(function(res) {
          document.getElementsByClassName('inner')[0].innerHTML = _vm.innerHTML
        })
        .catch(function(error) {
          document.getElementsByClassName('inner')[0].innerHTML = _vm.innerHTML
        })
    }
  }
}
</script>
<style scoped lang="scss">
.container-pra {
  padding: 32px 42px;
  box-sizing: border-box;
  .header {
    text-align: center;
    .title {
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #000000;
      line-height: 28px;
      margin: 12px 0 24px 0;
    }
  }
  .inner {
    a {
      color: #007dff !important;
    }
    p {
      font-size: 14px;
      font-family: FZLTHJW, FZLTHJW-Regular;
      font-weight: 400;
      text-align: left;
      color: #333333;
      line-height: 18px;
    }
  }
}
</style>
