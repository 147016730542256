<template>
  <div class="error_con">
    <img src="../../assets/img/hwjsdkNull.svg" alt="" />
    <div class="text">
      <p>{{ app + ' ' + $t('appNotReg')}} </p>
    </div>
  </div>
</template>
<script>
import { getQueryValue } from '@/utils/utils'
export default {
  data() {
    return {
      app: getQueryValue('service')
    }
  }
}
</script>
<style lang="scss" scoped>
.error_con {
  width: 980px;
  height: 631px;
  background: #ffffff;
  margin: 0 auto;
  text-align: center;
  img {
    margin-top: 94px;
  }
  .text {
    display: flex;
    justify-content: center;
    p {
      width: 412px;
      height: 43px;
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: undefined;
      line-height: 20px;
      a {
        color: #1890ff !important;
        cursor: pointer;
      }
    }
  }
}
</style>
