<template>
  <div class="multi-account">
    <div class="multi-account-tips">{{ setMultiAccountTips }}</div>
    <aui-form
      ref="form"
      :model="formData"
      label-width="0"
      validate-type="text"
      :rules="rules"
      show-message
     >
      <aui-form-item label="" prop="maskUid" class="pwd-account">
        <aui-select 
          v-model="formData.maskUid" 
          :placeholder="$t('accountPleaseSelect')" 
          v-show='multiAccount'
          @change="multiAccountChange">
            <aui-option 
              v-for="item in multiAccountOptions" 
              :key="item.uidProxy" 
              :label="item.maskUid" 
              :value="item"></aui-option>
          </aui-select>
      </aui-form-item>

      <aui-form-item class="pwd-account-btn">
        <aui-button
        type="primary"
        class="pwd-button"
        @click="next"
        :disabled="btnState"
        >{{$t('next')}}</aui-button>
      </aui-form-item>
    </aui-form>
  </div>
</template>

<script>
import { Form, FormItem, Select, Option, Button } from '@aurora/vue';

export default {
  name: 'MultiAccount',
  components: {
    AuiForm: Form,
    AuiFormItem: FormItem,
    AuiSelect: Select,
    AuiOption: Option,
    AuiButton: Button,
  },
  props: {
    isMobile: Boolean,
    uidType: String,
    multiAccountOptions: Array,
  },
  data() {
    return {
      btnState: true,
      formData:{
        maskUid: '',
      },
      multiAccount: true,
      rules: {
        maskUid: [{ required: true, message: this.$t('ressetPwMultiAccount'), trigger: ['blur', 'change'] }],
      },
    }
  },
  computed: {
    setMultiAccountTips() {
      let email = /^[a-zA-Z0-9_\.\-\']+@([a-zA-Z0-9_\-]+\.)+([a-zA-Z0-9])+$/.test(this.uidType)
      return !email
        ? this.$t('resetPwMultiAccountPhone') 
        : this.$t('resetPwMultiAccountEmail');
    },
  },
  methods: {
    multiAccountChange(val){
      this.formData = val
      this.formData.uid = val.uidProxy;
      this.btnState = false;
    },
    next() {
      const vm = this;
      vm.$refs.form.validate(valid => {
        if(valid) {
          vm.btnState = false;
          vm.formData.page = 2;
          vm.$emit('changePage', vm.formData);
        }
      })
    }
  },
}
</script>


<style lang="scss" scoped>
  @mixin btnBackground($color, $opacity) {
    :deep(.aui-button.aui-button--primary.is-disabled), 
    :deep(.aui-button.aui-button--primary.is-disabled:active), 
    :deep(.aui-button.aui-button--primary.is-disabled:focus), 
    :deep(.aui-button.aui-button--primary.is-disabled:hover) {
      border-color: $color;
      background-color: $color;
      opacity: $opacity;
    }
  }
  :deep(.multi-account-tips) {
    font-size: 14px;
  }
  :deep(.aui-input-suffix .aui-input__inner) {
    height: 40px;
  }
  :deep(.aui-select) {
    margin-top: 20px;
  }
  .pwd-button {
    max-width: none;
    width: 100%;
    height: 40px;
    border-radius: 8px;
  }
  .pwd-account-btn {
    margin-bottom: 12px;
    @include btnBackground(#ea9fa3, 1);
  }
</style>
