<template>
  <div>
    <div class="visibulity" v-if="!isMobile"></div>
    <div class="pwd-main">
      <div class="pwd-title">{{ $t('warmTips') }}</div>

      <!-- 结束日期 -->
      <div 
        class="pwd-deadTime" 
        v-if="state === 1"
        v-html="$t('endTime', {time: deadTime})"
      ></div>

      <!-- 账号已过期 -->
      <div v-else-if="state === 2">
        <div class="expiration-time">{{ $t('expirationTips1') }}</div>
        <div class="expiration-time">{{ $t('expirationTips2') }}</div>
      </div>
      
      <!-- 无法重置 -->
      <div v-else-if="state === 3">
        <div class="reset-tips">{{ $t('warningTips1') }}</div>
        <div class="reset-tips">
          <div>{{ $t('warningTips2') }}</div>
          <a href="javascript:;" @click="openNew">{{ initPassword }}</a>
        </div>
      </div>
      
      <aui-button
        type="primary"
        class="pwd-button pwd-button-primary"
        v-if="state === 1 || state === 2"
        @click="resetPassword"
      >{{$t('modifyNow')}}</aui-button>

      <aui-button
        plain
        class="pwd-button pwd-button-gray"
        v-if="state === 1"
        @click="successLogin"
      >{{$t('doNotmodifyNow')}}</aui-button>

      <aui-button
        plain
        class="callback-button"
        v-if="state === 3"
        @click="callbackFn"
      >{{$t('backLoginTips')}}</aui-button>
    </div>
  </div>
</template>

<script>
import { Button } from '@aurora/vue';
import { initPassword } from '@/assets/js'
import { getQueryVal, callbackLoginMethod, getQueryValue, getUrlStr } from '@/utils/utils';

export default {
  name: 'PwdTips',
  components: {
    AuiButton: Button,
  },
  data() {
    return {
      state: 1,
      uid: '',
      deadTime: 0,
      callbackUrl: '',
      initPassword,
      serviceUrl: '',
    };
  },
  computed: {
    isMobile: function() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) || window.innerWidth <= 500)
    },
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);  
      window.addEventListener('popstate', this.popstate, false);
    }
    if (this.getCookie('cas-tgc')) {
      this.delCookie('cas-tgc')
    }
    this.state = Number(this.$route.query.state);
    if (this.$route.query.uid) {
      this.uid = this.$route.query.uid;
    }
    if (this.state === 1) {
      this.deadTime = this.$route.query.deadTime;
      this.callbackUrl = this.$route.query.callbackUrl;
    }
    if (this.$route.query.service) {
      this.serviceUrl = getQueryValue('service');
    }
  },
  unmounted() {
    const callScript = document.querySelector("script[data-callType='captchaJs']");
    if (callScript) {
      document.head.removeChild(callScript);
    }
    window.removeEventListener('popstate', this.popstate, false);
  },
  methods: {
    popstate() {
      this.callbackFn();
      history.pushState(null, null, document.URL);
    },
    // 修改密码
    resetPassword() {
      const query = { 
        type: 1,
        uid: this.uid,
        service: this.serviceUrl,
        tenantId: getQueryVal('tenantId')
      } 
      const queryString = getUrlStr(query);
      this.$router.push(`/resetPwd?${queryString}`);
    },
    // 暂不修改，登录成功
    successLogin() {
      window.localStorage && window.localStorage.setItem('hisPage', 'w3');
      location.href = this.callbackUrl;
    },
    callbackFn() {
      const query = { service: this.serviceUrl, tenantId: getQueryVal('tenantId') }
      const queryString = getUrlStr(query);
      this.$router.push(`${callbackLoginMethod(getQueryVal('urlType'))}?${queryString}`)
    },
    openNew() {
      window.open(this.initPassword);
    },
    getCookie(cookiename) {  
      const cookiestring = document.cookie;
      let start = cookiestring.indexOf(cookiename);
      if (start == -1) {
        return false;
      }
      start += cookiename.length + 1;
      const end = cookiestring.indexOf("; ", start);
      if (end == -1) {
        return true;
      }
      return true;
    },
    delCookie(cookiename) {
      var exp = new Date();  
      exp.setTime(exp.getTime() - 1);  
      const cval = this.getCookie(cookiename); 
      if (cval) {
        document.cookie = cookiename + "=;expires=" + exp.toGMTString() + ';path=/';
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.visibulity {
  height: 80px;
}
.pwd-main {
  width: 558px;
  margin: 0 auto;
  height: auto;
  background: #fff;
  padding: 64px 63px 101px 63px;
  box-sizing: border-box;

  .pwd-title {
    width: 100%;
    height: 42px;
    font-size: 32px;
    font-family: PingFangSC, PingFangSC-Semibold;
    text-align: center;
    color: #000000;
    line-height: 42px;
    margin-bottom: 29px;
  }
  
  .pwd-deadTime {
    width: 410px;
    font-size: 14px;
    margin: 0 auto;
  }

  .expiration-time {
    width: 410px;
    font-size: 14px;
    line-height: 18px;
    margin: 0 auto;
    color: #7f7f7f;
  }

  .reset-tips {
    width: 410px;
    font-size: 14px;
    margin: 0 auto;
    color: #7f7f7f;
    word-wrap: break-word;

    &:first-child {
      margin-bottom: 20px;
    }

    >a {
      color: #526ecc;
    }
  }
  .pwd-button {
    max-width: none;
    display: block;
    width: 100%;
    height: 48px;
    margin: 0 auto;
    font-size: 16px;
    border-radius: 4px;
  }

  .pwd-button-primary {
    margin-top: 30px;
    margin-bottom: 24px;
    border-color: #ea9fa3;
    background-color: #ea9fa3;
  }

  .pwd-button-gray {
    color: #f66f6a;
    border-color: #f2f2f2;
    background-color: #f2f2f2;
  }

  .callback-button {
    max-width: none;
    display: block;
    width: 208px;
    height: 40px;
    margin: 30px auto 0;
    font-size: 16px;
    color: #000;
    border-radius: 4px;
  }
}

@media screen and (max-width: 767px) {
  .main {
    background: #fff;
  }
  .pwd-main {
    width: 100%;
    margin-top: 35px;
    height: auto;
    background: #fff;
    padding: 0 20px;
    box-sizing: border-box;

    .pwd-title {
      font-size: 24px;
    }
    .expiration-time {
      width: 100%;
      font-size: 12px;
    }
    .pwd-deadTime {
      width: 100%;
      font-size: 14px;
    }
    .reset-tips {
      width: 100%;
      font-size: 12px;
      margin: 0 auto;
      word-wrap: break-word;

      &:first-child {
        margin-bottom: 20px;
      }

      >a {
        color: #007dff;
      }
    }
    .pwd-button {
      max-width: none;
      width: 100%;
      height: 36px;
      color: #1890ff;
    }
    .aui-btn-full {
      height: 36px;
      background: #ea9fa3;
    }
    .pwd-button-primary {
      color: #fff;
      margin-top: 24px;
      border-color: #f66f6a;
      background: #f66f6a;
    }
    .pwd-success-tips {
      width: 100%;
      margin: 40px auto 0;
      text-align: center;
      font-size: 12px;
      line-height: 20px;
    }
    .pwd-button-gray {
      color: #f66f6a;
    }
    .callback-button {
      width: 100%;
      height: 36px;
      color: #fff;
      font-size: 16px;
      border-color: #f66f6a;
      background: #f66f6a;
    }
  }
}
</style>