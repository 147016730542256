<template>
  <div id="captcha"></div>
</template>

<script>
import { checkFirstCaptcha, checkSecondCaptcha } from '@/api';
import { Modal } from '@aurora/vue';
import { isMobile } from '@/utils/utils';

export default {
  name: 'Captcha',
  props: {
    lang: {  
      type: String,
      default: 'zh-CN',
    },
    captchaObj: Object,
  },
  methods: {
    getCaptchaConstant() {
      const { appId, businessId, sceneId } = this.captchaObj;
      return { appId, businessId, sceneId };
    },
    // 滑块验证
    initData(params) {
      const vm = this;
      const { challenge, hcg, hct } = params;
      const CHALLENGEHCGT = { challenge, hcg, hct };
      const widthNum = isMobile() ? 260 : 320;
      var captchaInstance = initHuaweiCaptcha(
        { 
          // 配置参数
          ...vm.getCaptchaConstant(),
          ...CHALLENGEHCGT,
          serviceDomain: [vm.captchaObj.serviceDomain],
          mode: 'popup',
          width: widthNum,
          element: '#captcha',
          isCloseable: true,
          type: 2,
          lang: this.lang,
          popupStyles: {
            position: 'fixed'
          },
        },
        // 初始化
        function(instance) {
          captchaInstance.popupShow();
        },
        // 验证--等于0时代表成功--拿到validate data.validate 进行二次验证等操作
        function(data) {
          if (data.resultCode === 0) {
            // 滑块验证成功，进行二次验证
            const { challenge, hcg, hct, validate } = data;
            const val = { challenge, hcg, hct, validate };
            vm.checkSecond(val);
            captchaInstance.destroy();
          }
        },
        // 用户关闭验证码
        function() {
          vm.$emit('changeBtnState');
        },
        // 错误
        function(err) {
          vm.$emit('changeBtnState');
          vm.errorTips();
        }
      );
    },
    checkFirst() {
      // 第一次校验成功，初始化滑块
      checkFirstCaptcha(this.getCaptchaConstant()).then(res => {
        if (res.resultCode === 0) {
          this.initData(res);
        } else {
          this.$emit('changeBtnState');
          this.errorTips();
        }
      }).catch(() => {
        this.$emit('changeBtnState');
        this.errorTips();
      });
    },
    checkSecond(val) {
      const vm = this;
      const params = {
        ...vm.getCaptchaConstant(),
        ...val,
        lang: this.lang,
        type: 2,
      };

      checkSecondCaptcha(params).then(res => {
        if (res.resultCode === 0) {
          // 验证成功跳转下一步
          vm.$emit('nextPwd', res.proxy);
        } else {
          vm.$emit('changeBtnState');
          vm.errorTips();
        }
      }).catch(() => {
        vm.$emit('changeBtnState');
        vm.errorTips();
      });
    },
    errorTips() {
      Modal.message({
        message: this.$t('systemError'),
        status: 'error',
        duration: '1000'
      });
    },
  },
}
</script>