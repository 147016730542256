<template>
  <div>
    <div class="img-tips success-img" v-if="state === 1 || state === 2">
      <img src="../../assets/img/success-icon.png" alt="">
    </div>

    <div class="img-tips error-img" v-if="state === 3">
      <img src="../../assets/img/error-icon.png" alt="">
    </div>

    <!-- 修改成功 -->
    <div v-if="state === 1">
      <div class="pwd-title">{{ setPwdTitle }}</div>

      <div class="pwd-button-div" :class="{'pwd-button-divEn': lang === 'en_US'}">
        <aui-button
          plain
          class="pwd-button"
          @click="callbackLogin"
        >{{$t('backLogin')}}</aui-button>

        <aui-button
          type="primary"
          class="pwd-button pwd-button-primary"
          @click="resetHwPwd"
          v-if="hwState"
          :disabled="btnDisabled"
        >{{$t('resetHWPassword')}}</aui-button>
      </div>
      <div class="pwd-success-tips" v-if="hwState">{{ $t('pwdSuccessTips') }}</div>
    </div>

    <!-- 域密码修改成功 -->
    <div v-if="state === 2">
      <div class="success-tips-title">
        {{ $t('hwAccountSuccessTips') }}
      </div>

      <div 
        class="success-tips-time" 
        v-html="$t('hwAccountSuccessTime', { time: count })">
      </div>
    </div>

    <!-- 域密码修改失败 -->
    <div v-if="state === 3">
      <div class="error-tips-title">
        {{ $t('hwAccountErrorTips') }}
      </div>

      <aui-button
        type="primary"
        class="pwd-button pwd-button-error"
        @click="resetHwPwd"
      >{{$t('resetHWPassword')}}</aui-button>
    </div>
  </div>
</template>

<script>
import { Button } from '@aurora/vue';
import { resetDomainPassword } from '@/api';
import { getCookie, getQueryVal, callbackLoginMethod, getUrlStr } from '@/utils/utils';

export default {
  name: 'PwdSuccess',
  components: {
    AuiButton: Button,
  },
  props: {
    serviceUrl: String,
    hwState: {
      type: Boolean,
      default: false,
    },
    domainObj: Object
  },
  data() {
    return {
      state: 1,
      count: 3,
      timer: null,
      btnDisabled: false,
      lang: getCookie('lang'),
    }
  },
  computed: {
    setPwdTitle() {
      return Number(this.$route.query.type) === 1
        ? this.$t('changedSuccess') 
        : this.$t('updateSuccess');
    },
  },
  methods: {
    resetHwPwd() {
      this.btnDisabled = true;
      resetDomainPassword(this.domainObj).then(res => {
        this.btnDisabled = false;
        if (res.toString() === 'true') {
          this.state = 2;
          this.getTime();
        } else {
          this.state = 3;
        }

        if (!res.errorCode) return;

        if ([500, 'CAS-02-00035', 'CAS-02-00033'].includes(res.errorCode)) {
          this.state = 3;
        }
      }).catch(() => {
        this.btnDisabled = false;
        this.state = 3;
      });
    },
    callbackLogin() {
      const path = callbackLoginMethod(getQueryVal('urlType'))
      if(path == '/account'){
        localStorage.setItem('hisPage', 'hw')
        window.location.reload()
      }else {
        const query = { service: this.serviceUrl, tenantId: getQueryVal('tenantId') };
        const queryString = getUrlStr(query);
        this.$router.push(`${path}?${queryString}`)
      }
    },
    getTime() {
      this.timer = setInterval(() => {
        this.count--;
        if (this.count === 0) {
          clearInterval(this.timer);
          this.targetUrl()
        }
      }, 1000)
    },
    targetUrl (){
      const path = callbackLoginMethod(getQueryVal('urlType'))
      if(path == '/account'){
        localStorage.setItem('hisPage', 'hw')
        location.reload()
      }else {
        const query = { service: this.serviceUrl, tenantId: getQueryVal('tenantId') };
        const queryString = getUrlStr(query);
        this.$router.push(`${path}?${queryString}`)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.visibulity {
  height: 80px;
}
.pwd-main {
  width: 558px;
  margin: 0 auto;
  height: auto;
  background: #fff;
  padding: 64px 63px 101px 63px;
  box-sizing: border-box;

  .img-tips {
    margin: 0 auto 33px;

    >img {
      width: 100%;
    }
  }
  .success-img {
    width: 64px;
    height: 64px;
  }

  .error-img {
    width: 36px;
    height: 36px;
  }
  .pwd-title {
    width: 100%;
    height: 42px;
    font-size: 32px;
    font-family: PingFangSC, PingFangSC-Semibold;
    text-align: center;
    color: #000000;
    line-height: 42px;
    margin-bottom: 29px;
  }
  
  .pwd-button-div {
    width: 100%;
    display: flex;
    justify-content: center;

    :deep(.aui-button.aui-button--primary) {
      background-color: #1890ff;
    }

    :deep(.aui-button.aui-button--primary:focus), 
    :deep(.aui-button.aui-button--primary:hover) {
      border-color: #1890ff;
      background-color: #1890ff;
    }
  }
  .pwd-button {
    max-width: none;
    display: block;
    width: 212px;
    height: 40px;
    font-size: 16px;
    color: #1890ff;
    border-radius: 4px;
  }
  .pwd-button-primary {
    color: #fff;
    border-color: #1890ff;
    background-color: #1890ff;
  }
  .pwd-button-error {
    color: #fff;
    width: 230px;
    border-color: #1890ff;
    background-color: #1890ff;
    margin: 48px auto 0;
  }
  .pwd-success-tips {
    width: 370px;
    margin: 40px auto 0;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    color: #7f7f7f;
  }
  .success-tips-title {
    width: 100%;
    font-size: 20px;
    text-align: center;
  }
  .success-tips-time {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #7f7f7f;
    margin-top: 64px;
  }
  .error-tips-title {
    width: 100%;
    font-size: 18px;
    text-align: center;
    color: #333;
  }
}
@media screen and (max-width: 767px) {
  .main {
    background: #fff;
  }
  .pwd-main {
    width: 100%;
    margin-top: 35px;
    height: auto;
    background: #fff;
    padding: 0 20px;
    box-sizing: border-box;
    .img-tips {
      margin-top: 60px;
    }
    .pwd-title {
      font-size: 20px;
    }
    .pwd-button-divEn {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .pwd-button {
        max-width: none;
        width: 100%;
        margin: 0;

        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
    .pwd-button {
      max-width: none;
      height: 36px;
      background: #fff;
      color: #1890ff;
      border: 1px solid #d9d9d9;
    }
    .pwd-button-primary {
      color: #fff;
      border-color: #1890ff;
      background-color: #1890ff;
    }
    .pwd-success-tips {
      width: 100%;
      margin: 40px auto 0;
      text-align: center;
      font-size: 12px;
      line-height: 20px;
    }
    .pwd-button-error {
      color: #fff;
      border-color: #1890ff;
      background-color: #1890ff;
      margin: 48px auto 0;
      font-size: 16px;
      width: 100%;
    }
  }
}
</style>