<template>
  <div>
    <aui-input
      v-bind="$attrs"
      :type="iconActive ? 'text':'password'"
      autocomplete="new-password"
    >
      <template v-slot:suffix>
        <span
          :class="iconActive?'IconEyeopen':'IconEyeclose'"
          @click="iconActive = !iconActive"
        ></span>
      </template>
    </aui-input>
  </div>
</template>
<script>
import { IconEyeclose, IconEyeopen } from '@aurora/vue-icon'
import { Input } from '@aurora/vue'
export default {
  name: 'ssoPwd',
  inheritAttrs: false,
  components: {
    AuiInput: Input,
  },
  data() {
    return {
      iconActive: false
    }
  }
}
</script>
<style lang="scss" scoped>
span {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  padding: 12px;
}
.svg-icon {
  fill: #b5b9c3;
  vertical-align: middle;
  margin-top: -6px;
  margin-right: 3px;
  font-size: 18px;
}
#fa_IR,
#ar_SA {
  margin-right: 0px;
  margin-left: 3px;
}
.IconEyeopen {
  background-image: url('../../assets/img/icon_yanjing_on@2x.png');
}
.IconEyeclose {
  background-image: url('../../assets/img/icon_yanjing_off@2x.png');
}
@media screen and (max-width: 767px) {
  .svg-icon {
    fill: #000000;
  }
}
</style>
